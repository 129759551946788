import { Injectable, Injector } from '@angular/core';

import { LoggerService } from '../../../utilities/logger/logger.service';

import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { TnLoaderService } from '../../../utilities/tn-loader/tn-loader.service';
import * as _ from 'lodash';
import { AccountManagerService } from '../account/account-manager.service';
import { WebclientLoginService } from '../../../login/webclient-login.service';
import { WebclientService } from '../../webclient.service';
import { Router } from '@angular/router';
import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { SideNavService } from '../../../utilities/tn-side-nav/side-nav.service';
import { ExerciseService } from '../../exercise/exercise.service';
import { AccountService } from '../../../account/account.service';
import { TeamnoteApiService } from '../../../api/teamnote-api.service';

class SocketInstance {
  login: string; // user_id
  passcode: string; // session token
  socket?: any = null;
  client?: any = null;
  isConnecting?: boolean = false;
  isConnected?: boolean = false;

  isNeedReconnect?: boolean = false
  isOfflineMsgGot?: boolean = false;
  isOfflinePresenceGot?: boolean = false;

  reconnectTimer?: any = null;
  abnormalReconnectTimer?: any = null;
  numOfInitialConnectAttempt?: number = 0;

  // Times
  lastActiveTime?: number = 0;
  loginTime?: number = 0;       // TODO: check if still needed
  disconnectTime?: number = 0;

  messageChannelSub?: any = null;
  presenceChannelSub?: any = null;

  isSubscribedMessage?: boolean = false;
  messageQueueName?: string = "";
  messageTestSubInterval?: any;

  isSubscribedPresence?: boolean = false;
  presenceQueueName?: string = "";
  presenceTestSubInterval?: any;
}

interface SocketConnection {
  [userId: string]: SocketInstance
}

@Injectable()
export class SocketService {

  // Socket & Client object
  private _socket;
  private _client;

  // Connect params
  private _login: string;           // user_id
  private _passcode: string;        // session_token
  private _host: string = '/';

  // Controls
  _isConnected: boolean = false;
  _numOfInitialConnectAttempt: number = 0;
  _isConnecting: boolean = false;
  reconnectTimer: any = null;
  abnormalReconnectTimer: any = null;

  _messageChannelSub: any = null;
  _presenceChannelSub: any = null;

  // Times
  _lastActiveTime: number = 0;
  _loginTime: number = 0;       // TODO: check if still needed
  _disconnectTime: number = 0;

  // For Reconnecting
  _isNeedReconnect: boolean = false;
  _isOfflineMsgGot: boolean = false;
  _isOfflinePresenceGot: boolean = false;

  // Subscription Queue name
  isSubscribedMessage: boolean = false;
  messageQueueName: string = "";
  messageTestSubInterval: any;

  isSubscribedPresence: boolean = false;
  presenceQueueName: string = "";
  presenceTestSubInterval: any;

  // Callback functions
  private _callbacks: Object = {};

  // Websocket connections dict
  _socketConnections: SocketConnection = {};
  abnormalReconnectTimerByAccount = {};
  callbacksGroupByUserId: any = {};
  isConnectCallbackCalled: boolean = false;

  constructor(
    private _loggerService: LoggerService,
    private _tnLoaderService: TnLoaderService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _timestampService: TimestampService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _accountManagerService: AccountManagerService,
    private _tnNotificationService: TnNotificationService,
    private _sideNavService: SideNavService,
    private injector: Injector,
    private _accountService: AccountService,
    private _teamnoteApiService: TeamnoteApiService,
  ) { }

  getStompHost(isWebSocket: boolean) {
    this._loggerService.debug("Getting stomp host...");
    let protocol = "";
    if (isWebSocket) {
      protocol = this._teamnoteConfigService.config.HOST.WEB_CLIENT_SSL ? 'wss://' : 'ws://';
    } else {
      protocol = this._teamnoteConfigService.config.HOST.WEB_CLIENT_SSL ? 'https://' : 'http://';
    }
    let host = '';
    if (this._teamnoteConfigService.config.HOST.API_HOST) {
      host = this._teamnoteConfigService.config.HOST.API_HOST.split('//')[1].split(':')[0];
    } else {
      host = window.location.hostname;
    }
    let port = (this._teamnoteConfigService.config.HOST.WEB_CLIENT_PORT ? (':' + this._teamnoteConfigService.config.HOST.WEB_CLIENT_PORT) : '');

    let stompHostPath = protocol + host + port + '/stomp';
    if (isWebSocket) {
      stompHostPath += "/websocket";
    }
    return stompHostPath;
  }

  connectToWebSocket(userId, sessionToken) {
    if (this._isConnecting) {
      this._loggerService.debug('WebSocket is connecting...')
      return
    }
    this._isConnecting = true
    if (this._socket) {
      this.debug('EXISTING SOCKET');
    }

    let isWebSocket = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET;
    if (this._accountManagerService.getLoginFieldByFieldName('amqp_use_sockjs') == 1) {
      isWebSocket = false
    }

    this.stompConnect(userId, sessionToken, isWebSocket);
  }

  stompConnect(userId, passcode, isWebSocket): void {
    this._loggerService.debug("Connecting to web socket with userId [[[ {userId} ]]] and passcode [[[ {passcode} ]]]".replace("{userId}", userId).replace("{passcode}", passcode));

    this._numOfInitialConnectAttempt++;

    let stompHost = this.getStompHost(isWebSocket);
    this._loggerService.debug("Got stomp host: " + stompHost);

    this._loggerService.debug("Try to init WebSocket instance...");
    if (isWebSocket) {
      this._socket = new WebSocket(stompHost);
    } else {
      this._socket = new SockJS(stompHost);
    }
    this._loggerService.debug("WebSocket instance init successfully!");

    this._client = Stomp.over(this._socket);
    this._client.heartbeat.incoming = 55000;
    this._client.heartbeat.outgoing = 55000;

    this._login = userId;
    this._passcode = passcode;
    this._client.debug = (frame) => this.debug(frame);

    let headers = {
      login: this._login,
      passcode: this._passcode,
      host: this._host
    };

    // TODO: add back hash_password part?
    if (this._isNeedReconnect) {
      this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
    } else {
      this._tnLoaderService.showSpinner('LOADING.CONNECTING');
    }

    this._loggerService.debug("Connect to web socket" + headers);
    this._client.connect(
      headers, 
      (frame) => this.connectCallback(frame), 
      (error) => this.errorCallback(error)
    );
  }

  getClient(): boolean {
    return this._client
  }

  getClientConnection(): boolean {
    return this._client.connected
  }

  debug(string) {
    if (string != ">>> PING") {
      this._loggerService.info(string);
      this._loggerService.debug(">>>>>> [ Background info from WebSocket ] >>>>>>: " + string);

      // console.log('websocket debug callback info >>>', string)
      if (string.startsWith('did not receive server activity for the last')) {
        // disconnect manually
        this._loggerService.debug("Disconnecting web socket, after detected [did not receive...]");
        this.disconnectSocket(true);
        this.setIsNeedReconnectTrue();
        this.setDisconnectTime();

        this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');

        // try to reconnect the ws
        this._loggerService.debug("Reconnecting web socket after detected [did not receive...]");
        clearTimeout(this.abnormalReconnectTimer);
        this._loggerService.debug("Try to reconnect WebSocket after 1s..., after detected [did not receive...]")
        this.abnormalReconnectTimer = setTimeout(() => { 
          this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
          this._loggerService.debug("Try to reconnect, connect web socket again, after detected [did not receive...]");
          this.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
        }, 1000)
      }
    }
  }

  connectCallback(frame) {
    this._isConnected = true;
    this._isConnecting = false
    this._loginTime = _.now() / 1000;
    this._loggerService.debug("Socket connect success, set loginTime: " + this._loginTime);

    this.customConnectCallback(frame);
    this.subscribeToChannels();
  }
  customConnectCallback(frame) {
    // redirect to webclient services' onConnectCallback
  }

  errorCallback(error) {
    this._loggerService.debug("Socket errorCallback: " + error);
    this._isConnecting = false

    if (!this._isConnected) {
      if (
        this._numOfInitialConnectAttempt > 3 && 
        this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET && 
        _.includes([null, 0], this._accountManagerService.getLoginFieldByFieldName('amqp_use_sockjs')) &&
        this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_SOCKJS_FALLBACK &&
        !this._isNeedReconnect
      ) {
        this._loggerService.debug("Try to fallback to SockJS...");
        // Only try to fallback to SockJS if it was configured to use websocket, and fallback is allowed, after 3 failed connection attempts
        this.stompConnect(this._login, this._passcode, false);
        return;
      }
    }

    this.customErrorCallback(error);
  }

  customErrorCallback(error) {
    // redirect to webclient services' socketErrorCallback
  }

  customErrorCallback_v2(error, accUserId: string) {
    // redirect to target account's socketErrorCallback
  }

  checkIfNeedToReconnect() {
    this._loggerService.debug('>>> check user has logged In: [[[ {value} ]]]'.replace('{value}', this._accountManagerService.isLoggedIn.toString()));
    // check if trying ws reconnection after user assessing refused
    if (!this._accountManagerService.isLoggedIn) {
      return
    }

    // console.log("_isNeedReconnect?", this._isNeedReconnect);
    if (!this._client || !this._isConnected) { 
      if (this._isNeedReconnect) {
        this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

        clearTimeout(this.reconnectTimer);
        // console.log('try to reconnect after 1s ==================>')
        this._loggerService.debug("try to reconnect WebSocket after 3s...")
        this.reconnectTimer = setTimeout(() => { 
          this._loggerService.debug("To simulate reconnect, connect web socket again");
          this.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
        }, 1000)
      }
    }
  }

  subscribeToChannels() {
    this._loggerService.debug('Subscribing Channels...');
    this.isSubscribedMessage = false;
    this.isSubscribedPresence = false;
    let now = new Date().getTime();
    this.presenceQueueName = ["stomp-subscription-web-presence", this._login, now].join("-");

    this.messageQueueName = ["stomp-subscription-web-message", this._login, now].join("-");

    // Message channel
    this._messageChannelSub = this._client.subscribe(
      '/exchange/' + this._login + '.message/0', 
      (frame) => this.receiveMessage(frame), 
      {
        'user-id': this._login,
        ack: 'auto',
        'x-queue-name': this.messageQueueName
      }
    );
    // Presence channel
    this._presenceChannelSub = this._client.subscribe(
      '/exchange/' + this._login + '.presence/0', 
      (frame) => this.receivePresence(frame), 
      {
        'user-id': this._login,
        ack: 'auto',
        'x-queue-name': this.presenceQueueName
      }
    );

    this.testSubscription();
  }

  testSubscription(): void {
    // Test subscription after 2 seconds
    setTimeout(() => {
      this._loggerService.debug("Try to check subscription...");
      this.testMessageSubscription();
      this.testPresenceSubscription();
    }, 2000);
  }

  testMessageSubscription(): void {
    if (!this.isSubscribedMessage) {
      this._loggerService.debug("Check message subscription for the first time.");
      this.checkMessageSubscription();
      this._loggerService.debug("Setting up message sub checking interval");
      this.messageTestSubInterval = setInterval(() => {
        this.checkMessageSubscription();
      }, 5000);
    } else {
      this._loggerService.debug("Message subscription success. no need to check");
    }
  }

  checkMessageSubscription(): void {
    if (this.isSubscribedMessage) {
      this._loggerService.debug("Message queue subscribed. Clearing interval...");
      clearInterval(this.messageTestSubInterval);
      return;
    }

    let routingKey = ".message/0";
    let correlationId = "TEST_MESSAGE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': this.messageQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      this._loggerService.debug("Received message test sub response");
      this.postMessageSubscribe();
      this.checkMessageSubscription();
    };

    this._loggerService.debug("Sending message test sub message");
    this.sendMessage(
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postMessageSubscribe(): void {
    if (this.isSubscribedMessage) {
      return;
    }
    this.isSubscribedMessage = true;
    let queueBindMessage = {
      headers: {
        temp_qname: this.messageQueueName
      }
    };
    this._loggerService.debug('[messageQueueName in postMessageSubscribe] >>>' + this.messageQueueName)
    this._loggerService.debug("Pass a custom message response to set queue name binding...");
    this.receiveMessage(queueBindMessage, true);
  }

  receiveMessage(frame, isBinding?) {
    this.setLastActiveTime(frame.headers["timestamp"]);

    // Check queue bind here.
    if (frame.headers.type == MessageTypeConstant.QUEUE_BIND) {
      this._loggerService.debug("Handle queue bind message response");
      this.postMessageSubscribe();
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownMessageCallback(frame, isBinding);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      // let callback = this._callbacks[correlation_id];
      let callback = this.callbacksGroupByUserId[this._accountManagerService.userId][correlation_id];

      if (callback) {
        callback(frame, isBinding);
        // delete this._callbacks[correlation_id];
        delete this.callbacksGroupByUserId[this._accountManagerService.userId][correlation_id];
      } else {
        // this.unknownMessageCallback(frame);
      }
    } else {
      // this.unknownMessageCallback(frame);
    }
  }

  unknownMessageCallback(frame, isBinding?) {
    // redirect to general message callback in dataManager
  }

  testPresenceSubscription(): void {
    if (!this.isSubscribedPresence) {
      this._loggerService.debug("Check presence subscription for the first time.");
      this.checkPresenceSubscription();
      this._loggerService.debug("Setting up presence sub checking interval");
      this.presenceTestSubInterval = setInterval(() => {
        this.checkPresenceSubscription();
      }, 5000);
    } else {
      this._loggerService.debug("Presence subscription success. no need to check");
    }
  }

  checkPresenceSubscription(): void {
    if (this.isSubscribedPresence) {
      this._loggerService.debug("Presence queue subscribed. Clearing interval...");
      clearInterval(this.presenceTestSubInterval);
      return;
    }

    let routingKey = ".presence/0";
    let correlationId = "TEST_PRESENCE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': this.presenceQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      this._loggerService.debug("Received presence test sub response");
      this.postPresenceSubscribe();
      this.checkPresenceSubscription();
    };

    this._loggerService.debug("Sending presence test sub message");
    this.sendMessage(
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postPresenceSubscribe(): void {
    if (this.isSubscribedPresence) {
      return;
    }
    this.isSubscribedPresence = true;
    let queueBindPresence = {
      headers: {
        temp_qname: this.presenceQueueName
      }
    };
    this._loggerService.debug("Pass a custom presence response to set queue name binding...");
    this.receivePresence(queueBindPresence, true);
  }

  receivePresence(frame, isBinding?) {
    this.setLastActiveTime(frame.headers["timestamp"]);

    if (frame.headers.type == PresenceTypeConstant.QUEUE_BIND) {
      this._loggerService.debug("Handle queue bind presence response");
      this.postPresenceSubscribe();
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownPresenceCallback(frame, isBinding);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      // let callback = this._callbacks[correlation_id];
      let callback = this.callbacksGroupByUserId[this._accountManagerService.userId][correlation_id];

      if (callback) {
        callback(frame, isBinding);
        // delete this._callbacks[correlation_id];
        delete this.callbacksGroupByUserId[this._accountManagerService.userId][correlation_id];
      } else {
        // this.unknownPresenceCallback(frame);
      }
    } else {
      // this.unknownPresenceCallback(frame);
    }
  }

  unknownPresenceCallback(frame, isBinding?) {
    // redirect to general presence callback in dataManager
  }

  addCallbackFunction(correlationId: string, callback: Function): void {
    if (correlationId && callback) {
      // this._callbacks[correlationId] = callback;
      this.callbacksGroupByUserId[this._accountManagerService.userId][correlationId] = callback;
    }
  }

  sendMessage(routingKey, headers, body, correlationId?, callback?) {
    if (!this._client || !this._client.connected) {
      return;
    }
    this.addCallbackFunction(correlationId, callback);
    // all headers should have user-id
    headers['user-id'] = this._login;
    // add destination prefix 
    let destination = '/exchange/' + this._login + routingKey;
    this._client.send(destination, headers, body);
  }

  setLastActiveTime(time) {
    // if needed to reconnect & load offline -> dont update last active time
    if (!this._isNeedReconnect) {
      this._lastActiveTime = this._lastActiveTime < time ? time : this._lastActiveTime;
    }
    this._loggerService.debug("Set last active time: " + this._lastActiveTime);

    // TODO:
    if (typeof time != 'undefined' && this._loginTime == 0) {
      this._loginTime = time;
    }
  }

  disconnectSocket(isDisconnectedByServer: boolean) {
    this._loggerService.debug("Disconnecting web socket...");
    this._login = '';
    this._loginTime = 0;    // TODO: check if really need?
    
    this.isSubscribedMessage = false;
    this.messageQueueName = "";
    clearInterval(this.messageTestSubInterval);

    this.isSubscribedPresence = false;
    this.presenceQueueName = "";
    clearInterval(this.presenceTestSubInterval);

    if (this._client) {
      // this._client.disconnect(() => this.disconnectCallback(isDisconnectedByServer));

      // handle ws disconnection manually
      if (this._client.connected) {
        if (this._messageChannelSub) {
          this._messageChannelSub.unsubscribe();
        }
        
        if (this._presenceChannelSub) {
          this._presenceChannelSub.unsubscribe();
        }

        this._client.disconnect(() => this.disconnectCallback(isDisconnectedByServer));
      } else {
        this.disconnectCallback(isDisconnectedByServer)
      }
    }
  }
  disconnectCallback(isDisconnectedByServer: boolean) {
    if (this._isConnected) {
      this._numOfInitialConnectAttempt = 0;
    }

    this._messageChannelSub = null;
    this._presenceChannelSub = null;

    this._isConnected = false;

    this._socket = null;
    this._client = null;
    this._loggerService.debug('Web socket is disconnected successfully');
    // if (isDisconnectedByServer) {
    //   this.setIsNeedReconnectTrue();
    // }
  }

  // Handle reconnect
  setIsNeedReconnectTrue() {
    this._loggerService.debug("Set isNeedReconnect to true");
    this._isNeedReconnect = true;
    this._isOfflineMsgGot = false;
    this._isOfflinePresenceGot = false;
  }
  realSetIsNeedReconnectFalse() {
    this._isNeedReconnect = false;
    this._tnLoaderService.hideSpinner();
  }
  setIsNeedReconnectFalse() {
    if (this._isOfflineMsgGot && this._isOfflinePresenceGot) {
      this._loggerService.debug("Both offline message and offline presence are received, set isNeedReconnect to false");
      this.realSetIsNeedReconnectFalse();
    }
  }
  setOfflineMsgGot() {
    this._loggerService.debug("Received offline message");
    this._isOfflineMsgGot = true;
    this.setIsNeedReconnectFalse();
  }
  setOfflinePresenceGot() {
    this._loggerService.debug("Received offline presencce");
    this._isOfflinePresenceGot = true;
    this.setIsNeedReconnectFalse();
  }

  setDisconnectTime() {
    let dt = this._lastActiveTime ? this._lastActiveTime : this._timestampService.getNowSecondString();
    this._loggerService.debug("Set disconnect time in cookies: " + dt);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME, dt);
  }

  /* For multi websocket connection */
  clearAllSocketConnections(): void {
    let accounts = this._accountManagerService.allLoggedInAccountRes;
    
    _.each(accounts, (acc, accUserId) => {
      this.realSetIsNeedReconnectFalse_v2(accUserId);
      this.disconnectSocket_v2(true, accUserId);
    })

    this.clearAbnormalReconnectTimerByAccount();
    this.callbacksGroupByUserId = {};
    this.isConnectCallbackCalled = false;
    this._socketConnections = {};
  }

  clearAbnormalReconnectTimerByAccount(): void {
    _.each(_.keys(this.abnormalReconnectTimerByAccount), (accUserId) => {
      clearTimeout(this.abnormalReconnectTimerByAccount[accUserId]);
      // delete this.abnormalReconnectTimerByAccount[accUserId];
    })

    this.abnormalReconnectTimerByAccount = {};
  }

  connectWebSocketForAllExerciseUser(): void {
    let accounts = this._accountManagerService.allLoggedInAccountRes;
    console.log('accounts', accounts);

    _.each(accounts, (acc, userId) => {
      this._sideNavService.resetSideNavs_v2(acc.user.user_id);
      this.initAbnormalReconnectTimerByAccount(acc.user.user_id);
      this.initReconnectionTimerByAccount(acc.user.user_id);
      this.initReconnectionCountByAccount(acc.user.user_id);
      // init webSocket connections by account
      this.initSocketConnectionsByAccount(acc, userId);
      // init webSocket callback object by account
      this.initCallbacksCollectionByAccount(userId);
      this.initMessagesByAccount(acc.user.user_id);
      this.initRosterByAccount(acc.user.user_id);
      
      // start to connect websockets for each account
      this.connectToWebSocket_v2(acc.user.user_id || userId, acc.session_token);
    })
  }
  
  connectWebSocketForTargetExerciseUser(accUserId: string, connectedCallback?: Function): void {
    const exerciseAcc = this._accountManagerService.getLoggedInAccounResByUserId(accUserId);
    console.log('target exercise userinfo exerciseAcc', exerciseAcc);

    this._sideNavService.resetSideNavs_v2(exerciseAcc.user.user_id);
    this.initAbnormalReconnectTimerByAccount(exerciseAcc.user.user_id);
    this.initReconnectionTimerByAccount(exerciseAcc.user.user_id);
    this.initReconnectionCountByAccount(exerciseAcc.user.user_id);
    // init webSocket connections by account
    this.initSocketConnectionsByAccount(exerciseAcc, exerciseAcc.user.user_id);
    // init webSocket callback object by account
    this.initCallbacksCollectionByAccount(exerciseAcc.user.user_id);
    this.initMessagesByAccount(exerciseAcc.user.user_id);
    this.initRosterByAccount(exerciseAcc.user.user_id);
    
    // start to connect websockets for each account
    this.connectToWebSocket_v2(exerciseAcc.user.user_id || accUserId, exerciseAcc.session_token, connectedCallback);
  }

  initAbnormalReconnectTimerByAccount(accUserId: string): void {
    if (!this.abnormalReconnectTimerByAccount[accUserId]) {
      this.abnormalReconnectTimerByAccount[accUserId] = null;
    }
  }

  initActiveSocketConn(accUserId: string): void {
    let conn = this.getWebSocketConnectionByUserId(accUserId);
    console.log('target active conn', conn);
    this._socket = conn.socket;
    this._client = conn.client;

    // Connect params
    this._login = conn.login;           // user_id
    this._passcode = conn.passcode;        // session_token

    // Controls]
    this._isConnecting = conn.isConnecting;
    this._isConnected = conn.isConnected;
    this._numOfInitialConnectAttempt = conn.numOfInitialConnectAttempt;
    this.reconnectTimer = conn.reconnectTimer || null;
    this.abnormalReconnectTimer = conn.abnormalReconnectTimer || null;

    this._messageChannelSub = conn.messageChannelSub;
    this._presenceChannelSub = conn.presenceChannelSub;

    // Subscription Queue name
    this.isSubscribedMessage = conn.isSubscribedMessage;
    this.messageQueueName = conn.messageQueueName;
    this.messageTestSubInterval = conn.messageTestSubInterval;
    
    this.isSubscribedPresence = conn.isSubscribedPresence;
    this.presenceQueueName = conn.presenceQueueName;
    this.presenceTestSubInterval = conn.presenceTestSubInterval;

    // Times
    this._lastActiveTime = conn.lastActiveTime || 0;
    this._loginTime = conn.loginTime;
    this._disconnectTime = conn.disconnectTime || 0;

    // For Reconnecting
    this._isNeedReconnect = conn.isNeedReconnect || false;
    this._isOfflineMsgGot = conn.isOfflineMsgGot || false;
    this._isOfflinePresenceGot = conn.isOfflinePresenceGot || false;
  }
  
  switchActiveSocketConnection(accUserId: string): void {
    // do something else first?
    this.initActiveSocketConn(accUserId);
  }

  initRosterByAccount(userId: string): void {
    // initRosterByAccount in DataManagerService
  }

  initMessagesByAccount(accUserId: string): void {
    // initMessagesByAccount In MessageService
  }

  initReconnectionTimerByAccount(accUserId: string): void {
    // initReconnectionTimerByAccount In WebclientService
  }

  initReconnectionCountByAccount(accUserId: string): void {
    // initReconnectionCountByAccount In WebclientService
  }

  initCallbacksCollectionByAccount(accUserId: string): void {
    if (!this.callbacksGroupByUserId[accUserId]) {
      this.callbacksGroupByUserId[accUserId] = {};
    }
  }

  initSocketConnectionsByAccount(acc, accUserId?: string): void {
    if (!this._socketConnections[accUserId]) {
      this._socketConnections[accUserId] = {
        login: acc.user.user_id || accUserId,
        passcode: acc.session_token,

        socket: null,
        client: null,
        isConnecting: false,
        isConnected: false,

        isNeedReconnect: false,
        isOfflineMsgGot: false,
        isOfflinePresenceGot: false,

        reconnectTimer: null,
        abnormalReconnectTimer: null,
        numOfInitialConnectAttempt: 0,

        loginTime: 0,
        lastActiveTime: 0,
        disconnectTime: 0,

        messageChannelSub: null,
        presenceChannelSub: null,

        isSubscribedMessage: false,
        messageQueueName: "",
        messageTestSubInterval: null,

        isSubscribedPresence: false,
        presenceQueueName: "",
        presenceTestSubInterval: null,
      };
    }
  }

  getWebSocketConnectionByUserId(userId: string): SocketInstance {
    return this._socketConnections[userId];
  }

  connectToWebSocket_v2(accUserId: string, sessionToken: string, connectedCallback?: Function) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (conn.isConnecting) {
      this._loggerService.debug(`WebSocket ${accUserId} is connecting...`)
      return;
    }

    conn.isConnecting = true

    if (conn.socket) {
      // this.debug('EXISTING SOCKET');
      console.log('EXISTING SOCKET');
    }

    let isWebSocket = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET;
    if (this._accountService.getMainAccountLoginFieldByFieldName('amqp_use_sockjs') == 1) {
      isWebSocket = false
    }

    this.stompConnect_v2(accUserId, sessionToken, isWebSocket, connectedCallback);
  }

  stompConnect_v2(userId: string, passcode: string, isWebSocket: boolean, connectedCallback?: Function): void {
    // this._loggerService.debug("v2 Connecting to web socket with userId [[[ {userId} ]]] and passcode [[[ {passcode} ]]]".replace("{userId}", userId).replace("{passcode}", passcode));
    // console.log("v2 Connecting to web socket with userId [[[ {userId} ]]] and passcode [[[ {passcode} ]]]".replace("{userId}", userId).replace("{passcode}", passcode));

    let conn = this.getWebSocketConnectionByUserId(userId);

    if (conn.numOfInitialConnectAttempt) {
      conn.numOfInitialConnectAttempt++;
    } else {
      conn.numOfInitialConnectAttempt = 0;
    }

    let stompHost = this.getStompHost(isWebSocket);
    // this._loggerService.debug("Got stomp host: " + stompHost);

    // this._loggerService.debug("Try to init WebSocket instance...");
    if (isWebSocket) {
      conn.socket = new WebSocket(stompHost);
    } else {
      conn.socket = new SockJS(stompHost);
    }
    // this._loggerService.debug("WebSocket instance init successfully!");

    conn.client = Stomp.over(conn.socket);
    conn.client.heartbeat.incoming = 55000;
    conn.client.heartbeat.outgoing = 55000;

    conn.login = userId;
    conn.passcode = passcode;
    conn.client.debug = (frame) => this.debug_v2(frame, conn.login);

    let headers = {
      login: conn.login,
      passcode: conn.passcode,
      host: this._host
    };


    const _exerciseService = this.injector.get<ExerciseService>(ExerciseService); // solve the circular dependency

    console.log(`User id ${conn.login} headers: `, headers);
    // TODO: add back hash_password part?
    if (conn.isNeedReconnect) {
      if (userId === _exerciseService.activeExercise?.user_id) {
        this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
      }
    } else {
      if (userId === _exerciseService.activeExercise?.user_id) {
        this._tnLoaderService.showSpinner('LOADING.CONNECTING');
      } 
    }

    _exerciseService.setExerciseLoadingStatus(userId, true);

    // this._loggerService.debug("Connect to web socket" + headers);
    conn.client.connect(
      headers, 
      (frame: any) => this.connectCallback_v2(frame, conn.login, connectedCallback), 
      (error: any) => this.errorCallback_v2(error, conn.login)
    );
  }

  debug_v2(string, accUserId: string) {
    if (string != ">>> PING") {
      // console.log(`debug_v2 ${accUserId}`);
      // this._loggerService.info(string);
      // this._loggerService.debug(">>>>>> [ Background info from WebSocket ] >>>>>>: " + string);

      // console.log('websocket debug callback info >>>', string)
      if (string.startsWith('did not receive server activity for the last')) {
        // disconnect manually
        // this._loggerService.debug("Disconnecting web socket, after detected [did not receive...]");
        this.disconnectSocket_v2(true, accUserId);
        this.setIsNeedReconnectTrue_v2(accUserId);
        this.setDisconnectTime_v2(accUserId);

        this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');

        // try to reconnect the ws
        // this._loggerService.debug("Reconnecting web socket after detected [did not receive...]");
        if (accUserId in this.abnormalReconnectTimerByAccount) {
          clearTimeout(this.abnormalReconnectTimerByAccount[accUserId]);
        }

        const targetAccount = this._accountManagerService.getLoggedInAccounResByUserId(accUserId)
        // this._loggerService.debug("Try to reconnect WebSocket after 1s..., after detected [did not receive...]")
        this.abnormalReconnectTimerByAccount[accUserId] = setTimeout(() => { 
          this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

          const _exerciseService = this.injector.get<ExerciseService>(ExerciseService); // solve the circular dependency
          _exerciseService.setExerciseLoadingStatus(accUserId, true);
      
          // this._loggerService.debug("Try to reconnect, connect web socket again, after detected [did not receive...]");
          this.connectToWebSocket_v2(targetAccount.user.user_id || accUserId, targetAccount.session_token);
        }, 1000)
      }
    }
  }

  connectCallback_v2(frame: any, userId: string, connectedCallback?: Function) {
    let conn = this.getWebSocketConnectionByUserId(userId);
    
    conn.isConnected = true;
    conn.isConnecting = false
    conn.loginTime = _.now() / 1000;
    // conn.loggerService.debug("Socket connect success, set loginTime: " + this._loginTime);
    if (connectedCallback) {
      connectedCallback();
    }

    this.customConnectCallback_v2(frame);
    if (!this.isConnectCallbackCalled) {
      this.isConnectCallbackCalled = true;
      this.customConnectCallback(frame);
    }
    this.subscribeToChannels_v2(userId);
  }

  customConnectCallback_v2(frame) {
    // redirect to webclient services' onConnectCallback
  }

  subscribeToChannels_v2(userId: string) {
    let conn = this.getWebSocketConnectionByUserId(userId);

    // this._loggerService.debug('Subscribing Channels...');
    conn.isSubscribedMessage = false;
    conn.isSubscribedPresence = false;
    let now = new Date().getTime();

    conn.presenceQueueName = ["stomp-subscription-web-presence", conn.login, now].join("-");
    conn.messageQueueName = ["stomp-subscription-web-message", conn.login, now].join("-");

    // Message channel
    conn.messageChannelSub = conn.client.subscribe(
      '/exchange/' + conn.login + '.message/0', 
      (frame) => this.receiveMessage_v2(frame, false, conn.login), 
      {
        'user-id': conn.login,
        ack: 'auto',
        'x-queue-name': conn.messageQueueName
      }
    );
    // Presence channel
    conn.presenceChannelSub = conn.client.subscribe(
      '/exchange/' + conn.login + '.presence/0', 
      (frame) => this.receivePresence_v2(frame, false, conn.login), 
      {
        'user-id': conn.login,
        ack: 'auto',
        'x-queue-name': conn.presenceQueueName
      }
    );

    // console.log('current websocket connection', conn);
    this.testSubscription_v2(conn.login);
  }

  setLastActiveTime_v2(time, accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    // if needed to reconnect & load offline -> dont update last active time
    if (!conn.isNeedReconnect) {
      conn.lastActiveTime = conn.lastActiveTime < time ? time : conn.lastActiveTime;
      // console.log('conn.lastActiveTime', conn.lastActiveTime);
    }
    // this._loggerService.debug("Set last active time: " + this._lastActiveTime);

    // TODO:
    if (typeof time != 'undefined' && conn.loginTime == 0) {
      conn.loginTime = time;
    }
  }

  disconnectSocket_v2(isDisconnectedByServer: boolean, accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    // this._loggerService.debug(`Disconnecting web socket... for account ${accUserId}`);
    console.log(`Disconnecting web socket... for account ${accUserId}`);
    conn.login = '';
    conn.loginTime = 0;    // TODO: check if really need?
    
    conn.isSubscribedMessage = false;
    conn.messageQueueName = "";
    clearInterval(conn.messageTestSubInterval);

    conn.isSubscribedPresence = false;
    conn.presenceQueueName = "";
    clearInterval(conn.presenceTestSubInterval);

    if (conn.client) {
      // handle ws disconnection manually
      if (conn.client.connected) {
        if (conn.messageChannelSub) {
          conn.messageChannelSub.unsubscribe();
        }
        
        if (conn.presenceChannelSub) {
          conn.presenceChannelSub.unsubscribe();
        }

        conn.client.disconnect(() => this.disconnectCallback_v2(isDisconnectedByServer, accUserId));
      } else {
        this.disconnectCallback_v2(isDisconnectedByServer, accUserId)
      }
    }
  }
  disconnectCallback_v2(isDisconnectedByServer: boolean, accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (conn.isConnected) {
      conn.numOfInitialConnectAttempt = 0;
    }

    conn.messageChannelSub = null;
    conn.presenceChannelSub = null;

    conn.isConnected = false;

    conn.socket = null;
    conn.client = null;
    // this._loggerService.debug(`Account ${accUserId} Web socket is disconnected successfully`);
    console.log(`Account ${accUserId} Web socket is disconnected successfully`);
    // if (isDisconnectedByServer) {
    //   this.setIsNeedReconnectTrue();
    // }
  }

  receiveMessage_v2(frame, isBinding?, accUserId?: string) {
    this.setLastActiveTime_v2(frame.headers["timestamp"], accUserId);

    // Check queue bind here.
    if (frame.headers.type == MessageTypeConstant.QUEUE_BIND) {
      // this._loggerService.debug("Handle queue bind message response");
      this.postMessageSubscribe_v2(accUserId);
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownMessageCallback_v2(frame, isBinding, accUserId);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      let callback = this.callbacksGroupByUserId[accUserId][correlation_id];
      if (callback) {
        callback(frame, isBinding);
        delete this.callbacksGroupByUserId[accUserId][correlation_id];
      } else {
        // this.unknownMessageCallback(frame);
      }
    } else {
      // this.unknownMessageCallback(frame);
    }
  }

  unknownMessageCallback_v2(frame, isBinding?, userId?: string) {
    // redirect to general presence callback in dataManager
  }

  receivePresence_v2(frame, isBinding?, accUserId?: string) {
    this.setLastActiveTime_v2(frame.headers["timestamp"], accUserId);

    if (frame.headers.type == PresenceTypeConstant.QUEUE_BIND) {
      // this._loggerService.debug("Handle queue bind presence response");
      this.postPresenceSubscribe_v2(accUserId);
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownPresenceCallback_v2(frame, isBinding, accUserId);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      let callback = this.callbacksGroupByUserId[accUserId][correlation_id];
      if (callback) {
        callback(frame, isBinding);
        delete this.callbacksGroupByUserId[accUserId][correlation_id];
      } else {
        // this.unknownPresenceCallback(frame);
      }
    } else {
      // this.unknownPresenceCallback(frame);
    }
  }

  unknownPresenceCallback_v2(frame, isBinding?, userId?: string) {
    // redirect to general presence callback in dataManager
  }

  testSubscription_v2(accUserId: string): void {
    // Test subscription after 2 seconds
    setTimeout(() => {
      // this._loggerService.debug("Try to check subscription...");
      console.log(`try to connect websocket for user with user_id: ${accUserId}`);
      this.testMessageSubscription_v2(accUserId);
      this.testPresenceSubscription_v2(accUserId);
    }, 2000);
  }

  /* Message */
  testMessageSubscription_v2(accUserId: string): void {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (!conn.isSubscribedMessage) {
      // this._loggerService.debug("Check message subscription for the first time.");
      this.checkMessageSubscription_v2(accUserId);
      // this._loggerService.debug("Setting up message sub checking interval");
      conn.messageTestSubInterval = setInterval(() => {
        this.checkMessageSubscription_v2(accUserId);
      }, 5000);
    } else {
      // this._loggerService.debug("Message subscription success. no need to check");
      console.log(`Message subscription for userid ${accUserId} success. no need to check`);
    }
  }

  checkMessageSubscription_v2(accUserId: string): void {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (conn.isSubscribedMessage) {
      // this._loggerService.debug("Message queue subscribed. Clearing interval...");
      clearInterval(conn.messageTestSubInterval);
      return;
    }

    let routingKey = ".message/0";
    let correlationId = "TEST_MESSAGE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': conn.messageQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      // this._loggerService.debug("Received message test sub response");
      this.postMessageSubscribe_v2(accUserId);
      this.checkMessageSubscription_v2(accUserId);
    };

    // this._loggerService.debug("Sending message test sub message");
    this.sendMessage_v2(
      accUserId,
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postMessageSubscribe_v2(userId: string): void {
    let conn = this.getWebSocketConnectionByUserId(userId);

    if (conn.isSubscribedMessage) {
      return;
    }
    conn.isSubscribedMessage = true;
    let queueBindMessage = {
      headers: {
        temp_qname: conn.messageQueueName
      }
    };
    // this._loggerService.debug('[messageQueueName in postMessageSubscribe] >>>' + this.messageQueueName)
    // this._loggerService.debug("Pass a custom message response to set queue name binding...");
    this.receiveMessage_v2(queueBindMessage, true, conn.login);
  }

  /* Presence */
  testPresenceSubscription_v2(userId: string): void {
    let conn = this.getWebSocketConnectionByUserId(userId);

    if (!conn.isSubscribedPresence) {
      // this._loggerService.debug("Check presence subscription for the first time.");
      this.checkPresenceSubscription_v2(userId);
      // this._loggerService.debug("Setting up presence sub checking interval");
      conn.presenceTestSubInterval = setInterval(() => {
        this.checkPresenceSubscription_v2(userId);
      }, 5000);
    } else {
      // this._loggerService.debug("Presence subscription success. no need to check");
      console.log(`Presence subscription for userid ${userId} success. no need to check`);
    }
  }

  checkPresenceSubscription_v2(userId: string): void {
    let conn = this.getWebSocketConnectionByUserId(userId);

    if (conn.isSubscribedPresence) {
      // this._loggerService.debug("Presence queue subscribed. Clearing interval...");
      clearInterval(conn.presenceTestSubInterval);
      return;
    }

    let routingKey = ".presence/0";
    let correlationId = "TEST_PRESENCE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': conn.presenceQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      console.log('websocket callback', userId);
      // this._loggerService.debug("Received presence test sub response");
      this.postPresenceSubscribe_v2(userId);
      this.checkPresenceSubscription_v2(userId);
    };

    this._loggerService.debug("Sending presence test sub message");
    this.sendMessage_v2(
      userId,
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postPresenceSubscribe_v2(userId: string): void {
    let conn = this.getWebSocketConnectionByUserId(userId);

    if (conn.isSubscribedPresence) {
      return;
    }
    conn.isSubscribedPresence = true;
    let queueBindPresence = {
      headers: {
        temp_qname: conn.presenceQueueName
      }
    };
    // this._loggerService.debug("Pass a custom presence response to set queue name binding...");
    this.receivePresence_v2(queueBindPresence, true, conn.login);
  }

  sendMessage_v2(accUserId: string, routingKey, headers, body, correlationId?, callback?) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (!conn.client || !conn.client.connected) {
      return
    }
    this.addCallbackFunction_v2(accUserId, correlationId, callback);
    // all headers should have user-id
    headers['user-id'] = conn.login;
    // add destination prefix 
    let destination = '/exchange/' + conn.login + routingKey;
    conn.client.send(destination, headers, body);
  }

  addCallbackFunction_v2(userId: string, correlationId: string, callback: Function): void {
    if (correlationId && callback) {
      // if (!this.callbacksGroupByUserId[userId]) {
      //   this.callbacksGroupByUserId[userId] = {};
      // }

      this.callbacksGroupByUserId[userId][correlationId] = callback;
    }
  }

  errorCallback_v2(error, accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);
    // this._loggerService.debug("Socket errorCallback: " + error);
    console.log(`Socket errorCallback for account ${accUserId}: ` + error);
    conn.isConnecting = false;

    // if (!conn.isConnected) {
    //   if (
    //     conn.numOfInitialConnectAttempt > 3 && 
    //     this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET && 
    //     _.includes([null, 0], this._accountManagerService.getLoginFieldByFieldName('amqp_use_sockjs')) &&
    //     this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_SOCKJS_FALLBACK &&
    //     !this._isNeedReconnect
    //   ) {
    //     this._loggerService.debug("Try to fallback to SockJS...");
    //     // Only try to fallback to SockJS if it was configured to use websocket, and fallback is allowed, after 3 failed connection attempts
    //     this.stompConnect(conn.login, conn.passcode, false);
    //     return;
    //   }
    // }

    this.customErrorCallback_v2(error, accUserId);
  }

  async checkIfMuitiAccountNeedToReconnect() {
    let accounts = this._accountManagerService.allLoggedInAccountRes;

    // let exercises = await this._teamnoteApiService.syncGetAvailableExercise();
    // console.log('exercises', exercises);

    _.each(accounts, (acc, userId) => {
      // this._loggerService.debug('>>> check user has logged In: [[[ {value} ]]]'.replace('{value}', this._accountManagerService.isLoggedIn.toString()));
      // check if trying ws reconnection after user assessing refused
      // if (!this._accountManagerService.isLoggedIn) {
      //   return
      // }
  
      const conn = this.getWebSocketConnectionByUserId(userId);

      if (!conn) {
        return;
      }

      if (!conn.client || !conn.client.connected) { 
        if (conn.isNeedReconnect) {
          // this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

          if (userId in this.abnormalReconnectTimerByAccount) {
            clearTimeout(this.abnormalReconnectTimerByAccount[userId]);
          }

          this._loggerService.debug(`try to reconnect account ${userId} WebSocket after 1s...`)

          this.abnormalReconnectTimerByAccount[userId] = setTimeout(() => { 
            this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
  
            const _exerciseService = this.injector.get<ExerciseService>(ExerciseService); // solve the circular dependency
            _exerciseService.setExerciseLoadingStatus(userId, true);
        
            this.connectToWebSocket_v2(acc.user.user_id || userId, acc.session_token);
          }, 1000)
        }
      }
    })
  }

  isTargetAccountWebSocketConnected(accUserId?: string): boolean {
    let conn = this.getWebSocketConnectionByUserId(this._accountManagerService.userId);
    
    return conn ? conn.isConnected : false;
  }

  // Handle reconnect
  setIsNeedReconnectTrue_v2(accUserId: string) {
    // this._loggerService.debug(`Set Account ${accUserId} isNeedReconnect to true`);
    console.log(`Set Account ${accUserId} isNeedReconnect to true`);
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    conn.isNeedReconnect = true;
    conn.isOfflineMsgGot = false;
    conn.isOfflinePresenceGot = false;
  }
  realSetIsNeedReconnectFalse_v2(accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    conn.isNeedReconnect = false;
    this._tnLoaderService.hideSpinner();

    const _exerciseService = this.injector.get<ExerciseService>(ExerciseService); // solve the circular dependency
    _exerciseService.setExerciseLoadingStatus(accUserId, false);
  }
  setIsNeedReconnectFalse_v2(accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    if (conn.isOfflineMsgGot && conn.isOfflinePresenceGot) {
      // this._loggerService.debug(`[Account ${accUserId}] Both offline message and offline presence are received, set isNeedReconnect to false`);
      console.log(`[Account ${accUserId}] Both offline message and offline presence are received, set isNeedReconnect to false`);
      this.realSetIsNeedReconnectFalse_v2(accUserId);
    }
  }
  setOfflineMsgGot_v2(accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    // this._loggerService.debug(`[Account ${accUserId}] Received offline message`);
    console.log(`[Account ${accUserId}] Received offline message`);
    conn.isOfflineMsgGot = true;
    this.setIsNeedReconnectFalse_v2(accUserId);
  }
  setOfflinePresenceGot_v2(accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    // this._loggerService.debug(`[Account ${accUserId}] Received offline presence`);
    console.log(`[Account ${accUserId}] Received offline presence`);
    conn.isOfflinePresenceGot = true;
    this.setIsNeedReconnectFalse_v2(accUserId);
  }

  setDisconnectTime_v2(accUserId: string) {
    let conn = this.getWebSocketConnectionByUserId(accUserId);

    let dt = conn.lastActiveTime ? conn.lastActiveTime : this._timestampService.getNowSecondString();
    // this._loggerService.debug(`Set Account ${accUserId} disconnect time in cookies: ` + dt);
    console.log(`Set Account ${accUserId} disconnect time in cookies: ` + dt);

    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.DISCONNECT_TIME + `_${accUserId}`, dt);
  }
}
