<div class="datetime-picker-wrapper">
  <div class="picker-header">
    <h4>{{ header | translate }}</h4>
  </div>

  <div class="picker-wrapper">
    <div class="date-picker">
      <!-- <mat-form-field appearance="outline">
        <mat-label>Date range</mat-label>
        <mat-date-range-input [formGroup]="dateRange" [rangePicker]="rangePicker">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>YYYY/MM/DD - YYYY/MM/DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker> </mat-date-range-picker>
      </mat-form-field> -->

      <div class="date-picker-left">
        <mat-form-field appearance="outline">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="startDatepicker" [formControl]="dateRange.start">
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
      
      <div class="date-picker-right">
        <mat-form-field appearance="outline">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="endDatepicker" [formControl]="dateRange.end">
          <mat-hint>YYYY/MM/DD</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="time-picker">
      <div class="time-picker-left">
        <mat-form-field appearance="outline">
          <mat-label>From</mat-label>
          <input
            matInput
            name="selected_start_time"
            format="24"
            [(ngModel)]="timeRange.start"
            [ngxMatTimepicker]="startTimePicker"
            placeholder="Start Time"
            disableClick="true"
          />
          <mat-hint>HH:mm</mat-hint>
          <ngx-mat-timepicker-toggle matSuffix [for]="startTimePicker"></ngx-mat-timepicker-toggle>
          <mat-error *ngIf="timeRange.start === 'Invalid DateTime'">Invalid time</mat-error>
        </mat-form-field>
        <ngx-mat-timepicker
          color="primary"
          #startTimePicker
          dottedMinutesInGap
          appendToInput="true"
        ></ngx-mat-timepicker>
      </div>

      <div class="time-picker-right">
        <mat-form-field appearance="outline">
          <mat-label>To</mat-label>
          <input
            matInput
            name="selected_end_time"
            format="24"
            [(ngModel)]="timeRange.end"
            [ngxMatTimepicker]="endTimePicker"
            placeholder="End Time"
            disableClick="true"
          />
          <mat-hint>HH:mm</mat-hint>
          <ngx-mat-timepicker-toggle matSuffix [for]="endTimePicker"></ngx-mat-timepicker-toggle>
          <mat-error *ngIf="timeRange.end === 'Invalid DateTime'">Invalid time</mat-error>
        </mat-form-field>
        <ngx-mat-timepicker color="primary" #endTimePicker dottedMinutesInGap appendToInput="true"></ngx-mat-timepicker>
      </div>
    </div>
  </div>

  <div class="tn-bottom-button-wrapper">
    <div class="action-button cancel-white clickable" (click)="cancel()">
      {{ 'GENERAL.CANCEL' | translate }}
    </div>
    <div class="action-button cancel clickable" (click)="clear()">
      {{ 'GENERAL.CLEAR' | translate }}
    </div>
    <!-- 
        [ngClass]="{
        clickable: isDateTimeValid,
        unclickable: !isDateTimeValid
      }"
     -->
    <div
      class="action-button confirm clickable"
    
      (click)="confirm()"
    >
      {{ 'GENERAL.CONFIRM' | translate }}
    </div>
  </div>
</div>
