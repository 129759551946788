import { Injectable } from '@angular/core';

import { CompanyModule } from '../../../models/company-module';

import * as _ from 'lodash';
import { ModuleKeyDefinition, WebclientSideNavConfigurations } from '../../../constants/module.constant';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { TeamnoteApiService } from '../../../api/teamnote-api.service';
import { ModuleConfig } from '../../../webclient/webdav-material/models/webdav-material';
import { Modules } from '../../webdav-material/models/webdav-material';
import { AccountManagerService } from '../account/account-manager.service';

@Injectable()
export class ModuleManagerService {
  appConfigModules: string[];
  modules: string[];
  config_modules: {};
  available_features: any[]

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _accountManagerService: AccountManagerService,
  ) { }

  /**
   * Get default side nav module ordering
   * 
   * @returns {string[]} - default ordered module key array
   * @memberof ModuleManagerService
   */
  getDefaultModuleOrdering(): string[] {
    return [
      // HOME,
      ModuleKeyDefinition.NEWS,
      ModuleKeyDefinition.CHAT,
      ModuleKeyDefinition.SCHEDULE_MESSAGE,
      ModuleKeyDefinition.CONTACT,
      // PROFILE,
      ModuleKeyDefinition.QUESTIONNAIRE,
      ModuleKeyDefinition.JOB_DISPATCH,
      ModuleKeyDefinition.STORE_REPORT,
      // DUTY ROSTER,
      ModuleKeyDefinition.STORE_SCHEDULE,
      ModuleKeyDefinition.TRAINING,
      ModuleKeyDefinition.CORPORATE_MATERIAL,
      ModuleKeyDefinition.WORKFLOW,
      ModuleKeyDefinition.VIDEO_STREAMING,
      ModuleKeyDefinition.WEBDAV_MATERIAL
      // Training course,
      // Booking,
      // More
    ];
  }

  /**
   * Get module ordering of current company
   * 
   * If ordering is set to {null}, use default ordering
   * 
   * @returns {string[]} - ordered module key array
   * @memberof ModuleManagerService
   */
  getModuleOrderingOfCompany(): string[] {
    let ordering = this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_ORDERING;
    return ordering == null ? this.getDefaultModuleOrdering() : ordering;
  }

  /**
   * Get ordering index of a module
   * 
   * If module doesn't exists in the module ordering, put it at the bottom
   * 
   * @param {string} moduleKey - target module key
   * @returns {number} - order index
   * @memberof ModuleManagerService
   */
  getOrderIndexOfModule(moduleKey: string): number {
    let index =  _.indexOf(this.getModuleOrderingOfCompany(), moduleKey);
    return index;
  }

  /**
   * Set modules by /module response
   * 
   * @param {CompanyModule[]} modulesResp - /module response
   * @memberof ModuleManagerService
   */
  setModules(modulesResp: CompanyModule[], setupSideNav: Function): void {
    this.modules = _.map(modulesResp, 'module_key'); // store the default nav items 
    console.log('modules', this.modules);
    let sessionToken = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);

    if (sessionToken) {
      this._teamnoteApiService.getModuleConfig(sessionToken, (resp) => {
        // console.log(resp);

        this.config_modules = resp.modules

        if (!resp.global) {
          setupSideNav && setupSideNav()
          return
        }

        if (resp.global.side_menu_webclient && resp.global.side_menu_webclient.length > 0) {
          this.appConfigModules = resp.global.side_menu_webclient;

          if (this._accountManagerService.isEnableMultiAccountLogin()) {
            // if (this.checkIfModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
            if (this.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
              const chatIndex = this.appConfigModules.indexOf('chat');
              this.appConfigModules.splice(chatIndex + 1, 0, ModuleKeyDefinition.SCHEDULE_MESSAGE);
            }
          }
        } else {
          this.appConfigModules = [] // default empty if there is no web-client side menu config
        }

        // get available features
        this._teamnoteApiService.getAvailableFeatures(sessionToken, (features) => {
          this.available_features = features

          setupSideNav && setupSideNav()
        }, (err) => {
          console.error(err)
        })
      }, (err) => {
        // if the module config can not be got, then using the default side nav setup
        setupSideNav && setupSideNav()
      })
    } else {
      setupSideNav && setupSideNav()
    }
  }

  /**
   * Check if module exists
   * 
   * @param {string} targetModule - target module key
   * @returns {boolean} - if user has this module
   * @memberof ModuleManagerService
   */
  checkIfModuleExists(targetModule: string): boolean {
    return _.indexOf(this.modules, targetModule) !== -1;
  }

  resetModuleConfig(): void {
    this.appConfigModules = [];
    this.modules = [];
    this.config_modules = {};
    this.available_features = []
  }

  switchAccountModule(callback: Function): void {
    this.resetModuleConfig();
    let accountModules = this._accountManagerService.getAccountUserFieldByFieldNameByAccount('module', this._accountManagerService.userId)
    console.log('exercise user"s modules', accountModules);
    this.setModules(accountModules, callback);
  }

  checkIfExerciseRoleModuleExists(targetModule: string): boolean {
    const targetAccRes = this._accountManagerService.getLoggedInAccounResByUserId(this._accountManagerService.userId);
    
    if (!targetAccRes) {
      return false;
    }
    
    const targetAccModules = _.map(targetAccRes.user.module, 'module_key')
    // console.log('targetAcc?????', targetAccRes);

    return _.indexOf(targetAccModules, targetModule) !== -1;
  }
}
