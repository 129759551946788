<div class="tn-content">
  <div class="tn-content-full">
    <tn-page-title [title]="'WEBCLIENT.MENU.SCHEDULE_MESSAGE' | translate"></tn-page-title>
    <div class="schedule-message-container">
      <div class="sm-table-header">
        <mat-form-field class="sm-search-bar" color="accent">
          <mat-label>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.SEARCH_PLACEHOLDER' | translate }}</mat-label>
          <input matInput #input (keyup)="applyFilter($event)" placeholder="Message Content, Date Time" />
        </mat-form-field>
        <mat-form-field class="sm-status-filter" color="accent">
          <mat-label>Status Filter</mat-label>
          <mat-select [(ngModel)]="selectedStatus" (selectionChange)="onStatusfilterChange($event)">
            <mat-option value="-1">All</mat-option>
            <mat-option *ngFor="let op of statusOption" [value]="op.value">{{ op.optionStr }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="sm-refresh">
          <i class="fa fa-fw fa-refresh text-color-primary clickable" (click)="refreshScheduleMessage()"></i>
        </div>
        <div class="sm-action-btns">
          <!-- <button class="btn tn-button-primary btn-block" (click)="addScheduleMessage()"> -->
          <!-- <button mat-raised-button color="accent" (click)="addScheduleMessage()"> -->
            <!-- Mass Update btns group -->
          <ng-container *ngIf="isShowMassUpdateBtns">
            <div class="action-btn-container">
              <button
                mat-icon-button
                class="material-icons app-toolbar-menu"
                matTooltip="Mass Send"
                color="accent"
                (click)="MassUpdateSendScheduleMsgNow()"
              >
                <mat-icon>send</mat-icon>
              </button>

              <button
                mat-icon-button
                class="material-icons app-toolbar-menu"
                matTooltip="Mass Delay Send"
                color="info"
                (click)="MassDelaySendScheduleMsg()"
              >
                <mat-icon>schedule</mat-icon>
              </button>

              <button
                mat-icon-button
                class="material-icons app-toolbar-menu save-button"
                matTooltip="Mass Cancel"
                color="warn"
                (click)="MassUpdateCancelScheduleMsg()"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </ng-container>

          <!-- <button class="btn tn-button-primary btn-block action-btn" (click)="toggleBulkActionMode()">
            {{ 'WEBCLIENT.SCHEDULE_MESSAGE.BULK_ACTION' | translate }}
          </button> -->
        </div>
        <div>
          <!-- <button class="btn tn-button-primary btn-block" (click)="addScheduleMessage()"> -->
          <!-- <button mat-raised-button color="accent" (click)="addScheduleMessage()"> -->
          <button class="btn tn-button-primary btn-block" (click)="addScheduleMessage()">
            {{ 'WEBCLIENT.SCHEDULE_MESSAGE.ADD_SCHEDULE_MESSAGE' | translate }}
          </button>
        </div>
        <!-- <div>
          <button class="btn tn-button-primary btn-block" (click)="test()">
            test
          </button>
        </div> -->
      </div>

      <div class="sm-table-body">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Checkbox Column -->
          <!-- <ng-container matColumnDef="select" *ngIf="isMassUpdateMode"> -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <!-- <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="scheduleMsgSelection.hasValue() && isAllSelected()"
                            [indeterminate]="scheduleMsgSelection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox> -->
            </th>
            <!-- [checked]="scheduleMsgSelection.isSelected(row)" -->
            <td mat-cell *matCellDef="let row" style="width: 50px;">
              <mat-checkbox
                *ngIf="row.isToBeApproved"
                color="accent"
                [disabled]="row.status === 3"
                (click)="$event.stopPropagation()"
                (change)="$event ? scheduleMsgSelection.toggle(row) : null"
                [(ngModel)]="row.isSelected"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="scheduled_send_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.DATETIME' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="datetime-td">{{ row.dateTimeDisplay }}</td>
          </ng-container>

          <ng-container matColumnDef="targets">
            <th mat-header-cell *matHeaderCellDef>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.TARGETS' | translate }}</th>
            <td mat-cell *matCellDef="let row" class="targets-td">
              <div class="targets-container">
                <!-- userContact -->
                <div class="target-users-container" *ngIf="row.recipients.length">
                  <div class="target-user" *ngFor="let contact of row.recipients">
                    <div class="avatar-container">
                      <div class="avatar-wrapper">
                        <div
                          class="avatar"
                          [ngClass]="{
                            'default-contact-avatar': contact.user_id,
                            'default-group-avatar': contact.user_group_id
                          }"
                          [ngStyle]="{ 'background-image': contact.avatarImageSrc }"
                        ></div>
                      </div>
                    </div>
                    <div class="info-wrapper">
                      <div class="ellipsis-name text-color-primary">
                        {{ contact.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- chat -->
                <div class="target-chats-container" *ngIf="row.chats.length">
                  <div class="target-chat" *ngFor="let chat of row.chats">
                    <div class="avatar-wrapper">
                      <div
                        class="avatar"
                        [ngClass]="{
                          'default-contact-avatar': chat.t == PRESENCE_TYPE.INDIVIDUAL_CHAT,
                          'default-group-avatar': chat.t != PRESENCE_TYPE.INDIVIDUAL_CHAT
                        }"
                        [ngStyle]="{ 'background-image': chat.picBgUrl }"
                      ></div>
                    </div>
                    <div class="info-wrapper">
                      <div class="row1">
                        <div class="ellipsis-name col1 text-color-primary">
                          {{ chat.displayName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.MESSAGE_CONTENT' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="msg-content-td">
              <!-- {{ row.message }} -->
              <div *ngIf="row.message_type == MSG_TYPE.TEXT">
                <div class="text-container">{{ row.messageDisplay }}</div>
              </div>
              <div *ngIf="row.message_type == MSG_TYPE.ATTACHMENT">
                <div class="attachment-container">
                  <div
                    class="attachment-wrapper"
                    [ngClass]="{
                    'attachment-photo-wrapper': row.messageDisplay.type == attachmentTypes.photo,
                    'attachment-video-wrapper': row.messageDisplay.type == attachmentTypes.video,
                    'attachment-file-wrapper':
                      row.messageDisplay.type == attachmentTypes.file ||
                      row.messageDisplay.type == attachmentTypes.audio,
                    }"
                  >
                    <div class="attachment-row">
                      <div class="attachment">
                        <div class="photo" *ngIf="row.messageDisplay.type == attachmentTypes.photo">
                          <div class="photo-view" [ngStyle]="{ 'background-image': row.messageDisplay.imgSrc }"></div>
                        </div>
                        <div class="video" *ngIf="row.messageDisplay.type == attachmentTypes.video">
                          <div class="file-name">
                            <div class="file-icon form-attachment-video-icon"></div>
                            {{ row.messageDisplay.name }}
                          </div>
                        </div>
                        <div
                          class="file"
                          *ngIf="
                            row.messageDisplay.type == attachmentTypes.file ||
                            row.messageDisplay.type == attachmentTypes.audio
                          "
                        >
                          <div class="file-name">
                            <div class="file-icon form-attachment-file-icon"></div>
                            {{ row.messageDisplay.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="row.message_type == MSG_TYPE.STICKER">
                <div class="sticker-container">
                  <div class="sticker-wrapper">
                    <div class="sticker" [ngStyle]="{ 'background-image': row.messageDisplay.stickerBgUrl }"></div>
                  </div>
                </div>
              </div>
              <div *ngIf="row.message_type == MSG_TYPE.LOCATION">
                <div class="location-wrapper">
                  <tn-location-message [body]="row.messageDisplay"></tn-location-message>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="require_confirm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="status-td">
              {{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.REQUIRED_CONFIRM' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="status-td">
              {{ row.require_confirm ? 'Yes' : 'No' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="status-td">
              {{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.STATUS' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="status-td">
              <div class="status-wrapper">
                <!-- <ng-container *ngIf="row.status == 0"> -->
                <ng-container>
                  <span *ngIf="!row.isToBeApproved">{{ row.statusDisplay }}</span>
                  <span *ngIf="row.isToBeApproved">{{ 'WEBCLIENT.SCHEDULE_MESSAGE.STATUS.WATING_FOR_APPROVAL' | translate }}</span>
                </ng-container>
                <button
                  mat-icon-button
                  class="material-icons app-toolbar-menu save-button"
                  matTooltip="Need Approve"
                  color="accent"
                  *ngIf="row.isToBeApproved && (row.status === 0 || row.status === 4)"
                >
                  <mat-icon>info</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.ACTION' | translate }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
              <ng-container *ngIf="row.isToBeApproved">
                <div class="action-btn-container">
                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu"
                    matTooltip="Send"
                    color="primary"
                    (click)="sendScheduleMsgNow(row)"
                  >
                    <mat-icon>send</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu"
                    matTooltip="Delay Send"
                    color="info"
                    (click)="delaySendScheduleMsg(row)"
                  >
                    <mat-icon>schedule</mat-icon>
                  </button>

                  <!-- <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu save-button"
                    matTooltip="Cancel"
                    color="warn"
                    (click)="cancelScheduleMsg(row.scheduled_message_id)"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button> -->
                </div>
              </ng-container>
              <ng-container>
                <div class="action-btn-container">
                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu save-button"
                    matTooltip="Edit"
                    color="primary"
                    (click)="editScheduleMsg(row)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button
                    *ngIf="row.status === 0 || (row.isToBeApproved && row.status != 3)"
                    mat-icon-button
                    class="material-icons app-toolbar-menu save-button"
                    matTooltip="Cancel"
                    color="warn"
                    (click)="cancelScheduleMsg(row.scheduled_message_id)"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    class="material-icons app-toolbar-menu"
                    matTooltip="Delete"
                    color="warn"
                    (click)="deleteScheduleMsg(row)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ 'timesup-schedule-msg': row.isToBeApproved }"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
              <span>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.TABLE.EMPTY_MESSAGE_PLACEHOLDER' | translate }}</span>
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
        class="mat-paginator-sticky"
      ></mat-paginator>
    </div>
  </div>
</div>
