import { Injectable } from '@angular/core'
import { TeamnoteApiService } from '../../api/teamnote-api.service'

import * as _ from 'lodash'
import { TeamNoteApiConstant } from '../../constants/api.constant'
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant'
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service'
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service'
import { ExerciseComponent } from './exercise.component'
import { AccountService } from '../../account/account.service'
import { AccountManagerService } from '../services/account/account-manager.service'
import { SocketService } from '../services/socket/socket.service'
import { LoggerService } from '../../utilities/logger/logger.service'
import { BehaviorSubject } from 'rxjs'

export interface Exercise {
  company_domain: string;
  user_name: string;
  user_id?: string;
  user?: any;
  company_name: string;
  device_token?: string;
  is_loading?: boolean;
}

@Injectable()
export class ExerciseService {
  exercises: Exercise[] = []
  activeExercise: Exercise = null;
  activeExercise$: BehaviorSubject<Exercise> = new BehaviorSubject<Exercise>(null);
  exercises$: BehaviorSubject<Exercise[]> = new BehaviorSubject<Exercise[]>(null);
  dialogRef: any = null;

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _tnDialogService: TnDialogService,
    private _accountService: AccountService,
    private _accountManagerService: AccountManagerService,
    private _socketService: SocketService,
    private _loggerService: LoggerService,
  ) {}

  openExerciseSelectionModal(accessToken: string, callback?: Function) {
    this.dialogRef = this._tnDialogService.openTnDialog(
      ExerciseComponent,
      {
        exercises: this.exercises,
        accessToken: accessToken,
        callback: callback,
      },
      { disableClose: true }
    )
  }

  connectWebSocketForExerciseUsers(selectedExerciseDomain: string, exerciseUserName: string): void {
    if (this.dialogRef) {
      this.closeExerciseComponentDialog();
    }

    console.log('selectedExerciseDomain', selectedExerciseDomain);
    const activeExercise = _.find(this.exercises, { company_domain: selectedExerciseDomain, user_name: exerciseUserName })
    // this.exercises = _.concat(activeExercise, _.filter(this.exercises, (ex) => ex.company_domain !== activeExercise.company_domain && ex.user_name !== activeExercise.user_name))
    this.exercises = _.concat(activeExercise, _.without(this.exercises, activeExercise));
    // console.log('ordered this.exercises', this.exercises);

    console.log('connectWebSocketForExerciseUsers', this.exercises);
    this.updateActiveExercise(this.exercises[0]);

    // this._socketService.connectWebSocketForAllExerciseUser();
    // console.log('!!!!!!!this.exercises!!!!!', this.exercises);
    // _.each(this.exercises, (ex) => {
    //   this._socketService.connectWebSocketForTargetExerciseUser(ex.user_id);
    // })
    this.connectExercisesByChunk();
  }

  async connectExercisesByChunk() {
    const exChunks = _.chunk(this.exercises, 3);

    for (const [index, chunk] of exChunks.entries()) {
      await Promise.all(chunk.map(ex => {
        return new Promise((resolve, reject) => {
          this._socketService.connectWebSocketForTargetExerciseUser(ex.user_id, () => {
            console.log(`connected success ${ex.user_id}`);
            resolve(true);
          });
        });
      }));

      console.log(`chunk!!!!!! ${index + 1} done!`);
  
      // const allConnected = chunk.every(ex => ex.isConnected);
      
      // if (!allConnected) {
      //   console.error("Not all exercises in this chunk connected successfully.");
      // }
    }
  }

  getAvailableExerciseUsers(accessToken: string, loginSingleAccountCallback?: Function) {
    this._teamnoteApiService.getAvailableExercise(
      accessToken,
      async (exercises) => {
        console.log('getAvailableExercise', exercises)
        this.exercises = exercises;
        // this.exercises = [];

        if (this.exercises.length > 0) {
          await this.awaitAllExerciseLoginRespBack(accessToken);
          console.log('awaitAllExerciseLoginRespBack done');
          this._loggerService.debug(`all exercise users' response just back`);

          if (this.checkIfAllExerciseLoggedIn()) {
            let prevExerciseRecord = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.PREV_EXERCISE_ROLE + `_${this._accountService.userId}`);

            if (prevExerciseRecord) {
              prevExerciseRecord = JSON.parse(prevExerciseRecord);
              const prevExercise = _.find(this.exercises, { company_domain: prevExerciseRecord?.company_domain, user_name: prevExerciseRecord?.user_name });
              
              if (prevExercise) {
                this._loggerService.debug(`previous exercise domain found: ${JSON.stringify(prevExercise)}`);
                // this._loggerService.debug(`Previous exercise exist, login exercise directly`);
                this.connectWebSocketForExerciseUsers(prevExercise.company_domain, prevExercise.user_name);
              } else {
                this.openExerciseSelectionModal(accessToken, this.connectWebSocketForExerciseUsers.bind(this));
              }
            } else {
              this.openExerciseSelectionModal(accessToken, this.connectWebSocketForExerciseUsers.bind(this));
            }
            // if (prevExercise) {
            //   this._loggerService.debug(`Previous exercise exist, login exercise directly`);
            //   // this.connectWebSocketForExerciseUsers(prevExerciseDomain);
            // } else {
            //   this.openExerciseSelectionModal(accessToken, this.connectWebSocketForExerciseUsers.bind(this));
            // }
          } 
          
        } else {
          /* no exercise mapping for the current account, then init default login flow of single user */
          if (loginSingleAccountCallback) {
            loginSingleAccountCallback();
          }
        }
      },
      err => {}
    )
  }

  async awaitAllExerciseLoginRespBack(accessToken: string): Promise<any> {
    let respPromiseList = _.map(this.exercises, (ex) => {
      return new Promise((resolve, reject) => {
        this.startSingleExerciseLoginFlow(ex, accessToken, (exerciseLoginResp) => {
          console.log('exerciseLoginResp', exerciseLoginResp);
          this._accountManagerService.addLoggedInAccount(exerciseLoginResp);
          // this._socketService.connectWebSocketForTargetExerciseUser(exerciseLoginResp.user.user_id);
          resolve(exerciseLoginResp);
        })
      })
    })

    return Promise.all(respPromiseList);
  }

  checkIfAllExerciseLoggedIn(): boolean {
    console.log('this.exercises', this.exercises);
    return _.every(this.exercises, (ex) => {
      return ex.user_id && this._accountManagerService.getLoggedInAccounResByUserId(ex.user_id);
    })
  }

  exerciseRegisterDevice(company_domain: string, user_name: string, otp: string, device_token: string, success: Function, failure: Function) {
    const params = {
      company_domain: company_domain,
      user_name: user_name,
      otp: otp,
      device_token: device_token,
      device_type: 'web',
      device_name: 'web-client',
      device_model: navigator.userAgent
    }

    const url = TeamNoteApiConstant.LOGIN.WEBCLIENT_REG_DEVICE
    this._teamnoteApiService.callApi(url, params, success, failure, true)
  }

  tryToLoginExercise(accessToken: string, exercise: Exercise, deviceToken: string, callback?: Function) {
    console.log(`tryToLoginExercise: \naccessToken: ${accessToken}\nexercise: ${JSON.stringify(exercise)}\ndeviceToken: ${deviceToken}`);
    
    this._teamnoteApiService.loginExercise(
      accessToken,
      exercise.company_domain,
      deviceToken,
      exercise.user_name,
      firstLoginResp => {
        console.log('loginExercise', firstLoginResp)

        if (!firstLoginResp.success) {
          // const exercise = this.getExerciseByCompanyDomain(companyDomain);

          this.exerciseRegisterDevice(
            exercise.company_domain,
            exercise.user_name,
            firstLoginResp.recipient,
            deviceToken,
            regDeviceResp => {
              console.log('exerciseRegisterDevice', regDeviceResp)

              this._teamnoteApiService.loginExercise(
                accessToken,
                exercise.company_domain,
                deviceToken,
                exercise.user_name,
                exerciseLoginResp => {
                  console.log('exerciseLoginResp', exerciseLoginResp)
                  // let exerciseLoginResp = respArray[0];
                  if (exerciseLoginResp.session_token) {
                    // store device token for target exercise
                    // this._localStorageManagerService.storeDeviceTokenByDomain(exercise.company_domain, exerciseLoginResp.user.user_name, deviceToken);
                    this._localStorageManagerService.storeDeviceTokenByDomain(this._accountService.userId, exerciseLoginResp.user.company_domain, exerciseLoginResp.user.user_name, deviceToken);

                    if (callback) {
                      exerciseLoginResp.user.device_token = deviceToken;
                      this.updateExercise(exercise.company_domain, exercise.user_name, 'user_id', exerciseLoginResp.user.user_id);
                      
                      callback(exerciseLoginResp);
                    }
                  }
                },
                err => {}
              )
            },
            err => {
              console.log('failed', err)
            }
          )
        } else {
          // target exercise has a device_token already, got the users login response of target exercise
          console.log('target exercise has a device_token already, got the users login response of target exercise')
          if (callback) {
            firstLoginResp.user.device_token = deviceToken;
            this.updateExercise(exercise.company_domain, exercise.user_name, 'user_id', firstLoginResp.user.user_id);
            
            callback(firstLoginResp);
          }
        }
      },
      err => {}
    )
  }

  /* isRelogin flag: whether need to relogin target exercise after websocket hitting AMQP 'Access refused' error */
  loginTargetExercise(exercise: Exercise, isRelogin?: boolean): void {
    this.startSingleExerciseLoginFlow(
      exercise, 
      this._accountService.accessToken,
      (exerciseLoginResp) => {
        this._accountManagerService.addLoggedInAccount(exerciseLoginResp);
        this._socketService.connectWebSocketForTargetExerciseUser(exerciseLoginResp.user.user_id);
      }
    );
  }

  startSingleExerciseLoginFlow(exercise: Exercise, accessToken: string, callback: Function): void {
    // let deviceToken = this._localStorageManagerService.getDeviceTokenByDomain(exercise.company_domain, exercise.user_name);
    let deviceToken = this._localStorageManagerService.getDeviceTokenByDomain(this._accountService.userId, exercise.company_domain, exercise.user_name);
    this.tryToLoginExercise(accessToken, exercise, deviceToken, callback);
  }



  closeExerciseComponentDialog(): void {
    this.dialogRef.close();
    this.dialogRef = null;
  }

  updateActiveExercise(ex: Exercise): void {
    this.activeExercise = ex;
    this.activeExercise$.next(this.activeExercise);
  }

  getExerciseByCompanyDomain(domain: string, user_name: string) {
    return _.find(this.exercises, { company_domain: domain, user_name: user_name })
  }

  getExerciseByExerciseUserId(uid: string) {
    return _.find(this.exercises, { user_id: uid })
  }

  // updateExerciseDeviceToken(domain: string, user_name: string, deviceToken: string) {
  //   let exercise = this.getExerciseByCompanyDomain(domain, user_name);
  //   exercise.device_token = deviceToken;
  //   // TODO: store device_token of exercise into localStorage, groupBy domain name
  // }


  updateExercise(domain: string, user_name: string, field: string, value: any) {
    let exercise = this.getExerciseByCompanyDomain(domain, user_name);

    if (exercise) {
      exercise[field] = value;
    }
  }

  setExerciseLoadingStatus(accUserId: string, status: boolean): void {
    const ex = this.getExerciseByExerciseUserId(accUserId);
    this.updateExercise(ex.company_domain, ex.user_name, 'is_loading', status)
    
    this.exercises$.next(this.exercises);
  }

}
