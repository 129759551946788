import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OauthService } from '../oauth.service';
import { LoginMethod } from '../models/login-methods-response';
import { LoginMethodConstant } from '../constants/login-methods.constant';
import { SamlService } from '../saml.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';

@Component({
  selector: 'tn-login-methods',
  templateUrl: './login-methods.component.html',
  styleUrls: ['./login-methods.component.scss']
})
export class LoginMethodsComponent implements OnInit {

  @Input() loginMethods: LoginMethod[] = [];
  @Input() isAllowTeamNoteLogin: boolean = true;
  @Output() handleExternalAuthLogin = new EventEmitter<boolean>(false);

  constructor(
    private _oauthService: OauthService,
    private _samlService: SamlService,
    private _localStorageManagerService: LocalStorageManagerService
  ) { }

  ngOnInit() {}

  onLoginMethodClick(method: LoginMethod): void {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE, method.auth_type);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME, method.auth_name);
    switch (method.auth_type) {
      case LoginMethodConstant.AUTH_TYPE.OAUTH:
        this.handleOAuthMethod(method);
        break;
      case LoginMethodConstant.AUTH_TYPE.SAML:
        this.handleSamlMethod(method);
        break;
    }
  }

  callbackOnLoginMethodPopUp(method: LoginMethod): void {
    // this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.LOGIN.CREDDOMAIN, method.config.);
    this.handleExternalAuthLogin.emit(true);
  }

  handleOAuthMethod(method: LoginMethod): void {
    this._oauthService.goToOAuthEndpointByMethod(
      method,
      (data) => {
        console.log('ready to call callbackOnLoginMethodPopUp');
        this.callbackOnLoginMethodPopUp(method);
      }
    );
  }

  handleSamlMethod(method: LoginMethod): void {
    this._samlService.goToSamlEndpointByMethod(
      method,
      (data) => {
        this.callbackOnLoginMethodPopUp(method);
      }
    );
  }

}
