import { Injectable } from '@angular/core';
import { LoginMethod } from './models/login-methods-response';
import { HttpClient } from '@angular/common/http';
import { TeamNoteApiConstant } from '../constants/api.constant';

import * as _ from 'lodash';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { TeamnoteApiService } from '../api/teamnote-api.service';
import { LoginMethodConstant } from './constants/login-methods.constant';
import { TeamNoteGeneralConstant } from '../constants/general.constant';
import {UtilitiesService} from '../utilities/service/utilities.service';

@Injectable()
export class OauthService {

  oAuthToken: string = null;
  oAuthIdTokenHrefString: string = LoginMethodConstant.OAUTH.ID_TOKEN;


  popupWindow: any = null;
  popupCheckInterval: any = null;

  constructor(
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _utilitiesService: UtilitiesService,
  ) { }

  clearOAuthToken(): void {
    this.oAuthToken = null;
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN);
  }

  tryToExtractOAuthAccessToken(originalHash: string): void {
    // remove the first "#" symbol of hash, as it will mess up getting the first param value
    // let hash = originalHash.substr(1);
    // let urlParam = new URLSearchParams(hash);   // URLSearchParams is not supported in IE....
    // this.oAuthToken = urlParam.get(LoginMethodConstant.OAUTH.ID_TOKEN);

    const hrefParams = originalHash.split('#');
    const hrefParts = _.last(hrefParams).split('&');
    if (hrefParts.length === 0) {
      // there are no params, return
      return;
    }

    const idTokenParam = _.find(hrefParts, (part) => {
      return part.substr(0, this.oAuthIdTokenHrefString.length) === this.oAuthIdTokenHrefString;
    });
    if (!idTokenParam) {
      // there is not id_token, return
      return;
    }
    this.oAuthToken = idTokenParam.substr(this.oAuthIdTokenHrefString.length);

    if (!this.oAuthToken) {
      // there is not id_token, return
      return;
    }
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN, this.oAuthToken);
  }

  getPopUpOption(): string {
    const windowHeight = window.outerHeight;
    const windowWidth = window.outerWidth;

    const height = windowHeight / 2;
    const width = windowWidth / 2;

    const top = windowHeight / 4;
    const left = windowWidth / 4;

    return `top=${top},left=${left},height=${height},width=${width},menubar=no,titlebar=no,toolbar=no`;
  }

  goToOAuthEndpointByMethod(method: LoginMethod, callback?: Function, isForceLogin?: boolean): void {
    let url = method.config.auth_endpoint.url;

    const endpoint = method.config.auth_endpoint;

    url += '?response_type=' + endpoint.response_type;
    url += '&client_id=' + endpoint.client_id;
    url += '&scope=' + endpoint.scope;
    url += '&nonce=' + endpoint.nonce;
    url += '&redirect_uri=' + window.location.origin + window.location.pathname;

    // if (isForceLogin) {
      // This is for Azure.
      url += '&prompt=select_account';
    // }


    // console.log('url', url);

    // setTimeout(() => {
    //   window.location.href = url
    // }, 50);

    // return;
    
    const option = this.getPopUpOption();
    this.popupWindow = window.open(
      url,
      TeamNoteGeneralConstant.POP_UP_WINDOW_NAME,
      option
    );

    if (!this.popupWindow) {
      return;
    }

    this.popupCheckInterval = window.setInterval(() => {
      if (this.popupWindow.closed) {
        window.clearInterval(this.popupCheckInterval);

        this.oAuthToken = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN);

        if (callback) {
          callback();
        }
      }
    }, 1000);
  }

  getUserNameWithOAuthToken(success: Function, failure: Function): void {
    if (!this.oAuthToken) {
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);
      return;
    }

    const url = TeamNoteApiConstant.LOGIN.OAUTH_ME;
    const params = {
      id_token: this.oAuthToken,
      auth_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME)
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        success(resp);
      },
      (err) => {
        failure(err);
      }
    );
  }

  webclientLoginWithOAuth(success: Function, failure: Function, otp?: string): void {
    const url = TeamNoteApiConstant.LOGIN.OAUTH_LOGIN;
    const params = {
      id_token: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN),
      auth_name: this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME),
      device_token: this._localStorageManagerService.getDeviceToken(),
      require_refresh_token: 1,
      otp: otp
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      (resp) => {
        if (resp._jwt) {
          this._teamnoteApiService.getE2EEPublicKey(
            (publicKey) => {
              this._utilitiesService.decodeJwt(resp._jwt, publicKey,
                (resp) => {
                  if (resp.success) {
                    this.clearOAuthToken();
                  }
                  success(resp);
                }
              );
            },
            () => {
              failure();
            }
          );
        } else {
          if (resp.success) {
            this.clearOAuthToken();
          }
          success(resp);
        }
      },
      failure
    );
  }

}
