import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { DataManagerService } from '../services/data/data-manager.service'
import { ScheduledMessageService } from './schedule-message.service'
import { AccountManagerService } from '../services/account/account-manager.service'
import { ScheduleMessageSetComponent } from './schedule-message-set/schedule-message-set.component'
import { ScheduleMessageSetService } from './schedule-message-set/schedule-message-set.service'
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service'
import { TimestampService } from '../../utilities/timestamp/timestamp.service'
import { ChatService } from '../services/data/chat/chat.service'
import { UserContactService } from '../services/data/user-contact/user-contact.service'
import { PresenceTypeConstant } from '../../constants/presence-type.constant'
import { MessageTypeConstant } from '../../constants/message-type.constant'
import { FileManagerService } from '../../utilities/file-manager/file-manager.service'
import { FieldAttachmentTypeMapping } from '../shared/custom-field/field-attachment/field-attachment.component'
import { addMinutes } from 'date-fns'
import { ModuleManagerService } from '../services/module/module-manager.service'
import { ModuleKeyDefinition } from '../../constants/module.constant'
import { SelectionModel } from '@angular/cdk/collections';

import _, { filter } from 'lodash'

@Component({
  selector: 'tn-schedule-message',
  templateUrl: './schedule-message.component.html',
  styleUrls: ['./schedule-message.component.scss']
})
export class ScheduleMessageComponent {
  // displayedColumns: string[] = ['scheduled_send_time', 'targets', 'message', 'require_confirm', 'status', 'action']
  displayedColumns: string[] = ['select', 'scheduled_send_time', 'targets', 'message', 'require_confirm', 'status', 'action']
  dataSource: MatTableDataSource<any>

  scheduleMsgSelection = new SelectionModel<any>(true, []);
  isMassUpdateMode: boolean = false;

  private isChatLoadedSub: Subscription

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  PRESENCE_TYPE = PresenceTypeConstant

  STATUS = {
    0: 'Pending',
    1: 'Processing',
    2: 'Delivered',
    3: 'Cancelled',
    4: 'Waiting For Approval',
  }

  statusOption = [
    { value: 0, optionStr: this.STATUS[0] },
    { value: 4, optionStr: this.STATUS[4] },
    { value: 2, optionStr: this.STATUS[2] },
    { value: 3, optionStr: this.STATUS[3] },
  ]

  attachmentTypes = FieldAttachmentTypeMapping

  MSG_TYPE = MessageTypeConstant

  isChatLoaded: boolean = false

  private unapprovedScheduleMessagesSub: Subscription;

  selectedStatus: number = -1; // -1

  originalScheduleMessages = [];
  searchKeywords: string = '';

  startToPeriodicMsgStatusCheckTimer = null;
  periodicMsgStatusCheckTimer = null;

  constructor(
    private _dataManagerService: DataManagerService,
    private _scheduledMessageService: ScheduledMessageService,
    private _scheduledMessageSetService: ScheduleMessageSetService,
    private _accountManagerService: AccountManagerService,
    private _tnNotificationService: TnNotificationService,
    private _timestampService: TimestampService,
    private _userContactService: UserContactService,
    private _chatService: ChatService,
    private _fileManagerService: FileManagerService,
    private _moduleManagerService: ModuleManagerService,

  ) {}

  test() {
    console.log('scheduleMsgSelection', this.scheduleMsgSelection);
    console.log(this.dataSource.data);
  }

  ngOnInit() {
    this.unapprovedScheduleMessagesSub = this._scheduledMessageService.unapprovedScheduleMessages$.subscribe((messages: { allMessages: any[], isPeriodicCall: boolean }) => {
      // console.log('unapprovedScheduleMessages updated, get schedule messages again', messages)
      if (this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
        let copy = _.cloneDeep(this._scheduledMessageService.scheduleMessages);
        this.initScheduleMessages(copy);

        if (messages && messages?.isPeriodicCall) {
          // timely check schedule message status('pending' to 'delivered')
          this.checkMessageStatusAndRefresh()
        }
      }
    });

    this.isChatLoadedSub = this._dataManagerService.isChatLoaded$.subscribe(isChatLoaded => {
      // console.log('Chat just Loaded')
      this.isChatLoaded = isChatLoaded
      if (isChatLoaded) {
        console.log('ScheduleMessageComponent')
        this.clearStatusPeriodicTimer();

        if (this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
          this.getScheduleMessages()
        }
      }
    })
  }

  ngOnDestroy() {
    this.clearStatusPeriodicTimer();
    this.isChatLoadedSub.unsubscribe()
    this.unapprovedScheduleMessagesSub.unsubscribe()

    // this._scheduledMessageService.updateIsInUnapprovedScheduleMessagesViewSubject(false)
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.initTableStuff()
    }
  }

  clearDataSource(): void {
    // this.dataSource = new MatTableDataSource([])
    // this.initTableStuff()

    this.setTableData([])
  }

  initTableStuff(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    // this.dataSource.filter = '';

    this.dataSource.filter = this.searchKeywords.trim().toLowerCase()
    // console.log('this.dataSource.filter', this.dataSource.filter);
    // if (this.isMassUpdateMode) {
    //   const noApproveMsgSelection = _.every(_.filter(this.dataSource.data, { isToBeApproved: true }), (msg) => msg.isSelected != true)

    //   if (noApproveMsgSelection) {
    //     this.toggleBulkActionMode();
    //   }
    // }
  }

  checkMessageStatusAndRefresh() {
    this.clearStatusPeriodicTimer();
          
    let timesUpMessages = _.filter(this.originalScheduleMessages, (msg) => {
      return msg.status == 0 && msg.require_confirm == 0 && this._timestampService.checkIfTimeBeforeToday(msg.scheduled_send_time)
    })          
    
    // console.log('timesUpMessages', timesUpMessages);

    if (timesUpMessages.length) {
      this.startToPeriodicMsgStatusCheckTimer = setTimeout(() => {
        this.refreshScheduleMessage(true, false, false);

        this.periodicMsgStatusCheckTimer = setInterval(() => {
          // console.log('timesUpMessages in timer', timesUpMessages)
          // console.log('this.originalScheduleMessages', this.originalScheduleMessages)
          let isAllMessageSent = _.intersection(_.map(this.originalScheduleMessages, 'scheduled_message_id'), _.map(timesUpMessages, 'scheduled_message_id'))
          if (isAllMessageSent.length === 0) {
            // stop periodicMsgStatusCheckTimer interval
            // console.log('clear timer');
            clearInterval(this.periodicMsgStatusCheckTimer);
            clearInterval(this.startToPeriodicMsgStatusCheckTimer);
          }

          // console.log('3 secs periodic status check');
          this.refreshScheduleMessage(true, false, false);
        }, 3000);
      }, 0);
    }
  }

  clearStatusPeriodicTimer() {
    if (this.startToPeriodicMsgStatusCheckTimer) {
      clearInterval(this.startToPeriodicMsgStatusCheckTimer)
    }

    if (this.periodicMsgStatusCheckTimer) {
      // console.log('clear periodScheduleMessagesTimer first');
      clearInterval(this.periodicMsgStatusCheckTimer);
    }
  }

  toggleBulkActionMode(): void {
    this.isMassUpdateMode = !this.isMassUpdateMode

    if (this.isMassUpdateMode) {
      this.displayedColumns = ['select', 'scheduled_send_time', 'targets', 'message', 'require_confirm', 'status', 'action']
    } else {
      this.resetSeletion();
      this.displayedColumns = ['scheduled_send_time', 'targets', 'message', 'require_confirm', 'status', 'action']
    }
  }

  // filterValues = {};
  onStatusfilterChange(event) {
    // console.log('onStatusfilterChange', event);
    
    if (event.value != -1) { 
      const filtered = _.filter(this.originalScheduleMessages, { status: event.value })
      // console.log(filtered)
      this.setTableData(filtered);
    } else {
      this.setTableData(this.originalScheduleMessages)
    }
    
    // if (event.value != -1) {
    //   this.filterValues['status'] = this.STATUS[event.value];
    //   this.dataSource.filter = this.filterValues['status'].trim().toLowerCase()
    // } else {
    //   this.filterValues = {};
    //   this.dataSource.filter = '';
    // }
  }

  setTableData(data) {
    this.dataSource = new MatTableDataSource(data)
    this.initTableStuff()
  }

  buildMessageDisplay(msgType: number, message: string): any {
    try {
      const messageBody = JSON.parse(message)

      switch (msgType) {
        case MessageTypeConstant.TEXT:
          return messageBody.message
        case MessageTypeConstant.ATTACHMENT:
          return {
            attachment_id: messageBody.attachment_id,
            imgSrc: this._fileManagerService.getBackgroundImgSrcByAttachmentId(messageBody.attachment_id),
            name: messageBody.filename,
            type: this._fileManagerService.getAttachmentType(messageBody.filename)
          }

        case MessageTypeConstant.STICKER:
          return {
            attachment_id: messageBody.attachment_id,
            stickerBgUrl: this._fileManagerService.getBackgroundImgSrcByAttachmentId(messageBody.attachment_id)
          }
        case MessageTypeConstant.LOCATION:
          return messageBody
      }
    } catch (error) {
      return message
    }

    return ''
  }

  parseScheduleMessages(scheduleMsg: any[]): any {
    // let sorted = _.orderBy(scheduleMsg, 'scheduled_send_time', 'asc')
    // console.log('sorted', sorted)

    return _.map(scheduleMsg, m => {
      let chat_ids = _.map(_.map(m.chats, 'chat_id'), chatId => {
        return this._chatService.getChatByChatId(chatId)
      })

      // console.log('this._chatService.chats', this._chatService.chats);
      // console.log('chat_ids', chat_ids);

      let userContacts = _.map(_.map(m.recipients, 'user_id'), uid => {
        return this._userContactService.getUserContactByUserId(uid)
      })

      if (m.status === 0) {
        if (m.isToBeApproved) {
          m.status = 4;
        }
      }

      return {
        ...m,
        dateTimeDisplay: this._timestampService.getDateTimeDisplay(m.scheduled_send_time, true),
        chats: _.sortBy(chat_ids, 'displayName'),
        recipients: _.sortBy(userContacts, 'name'),
        targets: JSON.stringify(_.map(userContacts, 'name')),
        statusDisplay: this.STATUS[m.status],
        messageDisplay: this.buildMessageDisplay(m.message_type, m.message)
      }
    })
  }

  getScheduleMessages(isNoNeedTimeBuffer?: boolean, isShowLoading?: boolean, isShowNotification?: boolean, isPeriodicCall?: boolean): void {
    // this.clearDataSource()

    this._scheduledMessageService.getAllScheduleMessages(
      () => {
        // let copy = _.cloneDeep(this._scheduledMessageService.scheduleMessages);
        // this.initScheduleMessages(copy);
      },
      isNoNeedTimeBuffer,
      isShowLoading,
      isShowNotification,
      isPeriodicCall
    )
  }

  initScheduleMessages(messages): void {
    let parsed = this.parseScheduleMessages(messages)
    this.originalScheduleMessages = _.cloneDeep(parsed);
    // console.log(parsed)

    let filtered = _.cloneDeep(parsed);
    if (this.selectedStatus != -1) {
      filtered = _.filter(filtered, { status: this.selectedStatus })
    }
    
    // this.dataSource = new MatTableDataSource(parsed)
    // this.initTableStuff()
    // this.setTableData(this.originalScheduleMessages)
    // console.log('before select', this.dataSource?.data);
    if (this.dataSource?.data) {
      filtered = this.initSelection(filtered);
    }

    this.setTableData(filtered)
  }

  initSelection(data): any {
    return _.map(data, (item) => {
      const prevRow = _.find(this.dataSource.data, { scheduled_message_id: item.scheduled_message_id })

      if (prevRow) {
        return {
          ...item,
          isSelected: !!prevRow.isSelected
        }
      }

      return item
    })
  }

  getSelectedScheduleMessageIds(): any {
    const selectedMsg = _.filter(this.dataSource.data, { isSelected: true })

    return _.map(selectedMsg, 'scheduled_message_id');
  }

  resetSeletion(): void {
    _.each(this.dataSource.data, (item) => {
      item.isSelected = false;
    })

    // console.log('after reset', this.dataSource.data);
  }

  get isShowMassUpdateBtns() {
    // return this.isMassUpdateMode && _.some(this.dataSource.data, (item) => item.isSelected)
    return _.some(this.dataSource?.data, (item) => item.isSelected)
  }

  addScheduleMessage(): void {
    this._scheduledMessageSetService.openSetScheduleMessageDialog(null, () => {
      this.getScheduleMessages(true, true)
    })
  }
  refreshScheduleMessage(isNotShowLoading?: boolean, isShowNotification?: boolean, isPeriodicCall?: boolean): void {
    this.getScheduleMessages(false, !isNotShowLoading, isShowNotification, isPeriodicCall)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.searchKeywords = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  editScheduleMsg(row): void {
    this._scheduledMessageSetService.openSetScheduleMessageDialog(_.cloneDeep(row), () => {
      // this.getScheduleMessages()
      this.getScheduleMessages(true, true)
    })
  }

  deleteScheduleMsg(row): void {
    this._tnNotificationService.showPrompt(
      null,
      'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.DELETE_CONFIRM',
      null,
      () => {
        this._scheduledMessageService.deleteScheduleMessage(
          null,
          null,
          row.scheduled_message_id,
          resp => {
            this._tnNotificationService.showCustomInfoByTranslateKey(
              'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.DELETE_SUCCESS'
            )
            this.getScheduleMessages()
          },
          err => {
            console.log(err)
            this._tnNotificationService.showSystemError()
          }
        )
      },
      false,
      true
    )
  }

  sendScheduleMsgNow(row): void {
    this._scheduledMessageService.updateApprovalScheduleMessage(
      row.scheduled_message_id,
      this._accountManagerService.companyDomain,
      this._accountManagerService.userId,
      resp => {
        // this._scheduledMessageService.getAllScheduleMessages();

        // console.log('send approval message now')
        this._scheduledMessageService.sendScheduleMessage(
          row.scheduled_message_id,
          resp => {
            // console.log('send approval schedule message successfully')
            // this._scheduledMessageService.getAllScheduleMessages();
            this.getScheduleMessages(false, true);

            this._tnNotificationService.showCustomInfoByTranslateKey(
              'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.SEND_SUCCESS'
            )
          },
          err => {
            this._tnNotificationService.showSystemError()
          }
        )
      },
      err => {
        console.log(err)
        this._tnNotificationService.showSystemError()
      },
      undefined, // 2
      1 // is_confirmed
    )
  }

  callDelaySendScheduleMsgApi(row, delayMins: number, resolve?: Function, reject?: Function) {
    this._scheduledMessageService.updateApprovalScheduleMessage(
      row.scheduled_message_id,
      this._accountManagerService.companyDomain,
      this._accountManagerService.userId,
      resp => {
        // console.log('send approval message now')
        // this._scheduledMessageService.getAllScheduleMessages();

        // let afterFiveMinutes = addMinutes(new Date(), 5);
        let afterAnyMinutes = addMinutes(new Date(), delayMins);
        const roundToCeilingMinutes = this._timestampService.getRoundToCeilingMinute(afterAnyMinutes)
        // console.log('roundToCeilingMinutes,', roundToCeilingMinutes);

        this._scheduledMessageService.addScheduleMessage(
          this._accountManagerService.companyDomain,
          row.message_type,
          JSON.parse(row.message),
          _.map(row.recipients, 'user_id'),
          _.map(row.chats, 'chat_id'),
          this._accountManagerService.userId,
          roundToCeilingMinutes,
          1, // require_confirm, 0
          resp => {
            // // console.log('send approval message now', resp)
            // this._tnNotificationService.showCustomInfoByTranslateKey(
            //   'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.RESCHEDULE_SUCCESS'
            // )

            if (!resolve) {
              // console.log('delay send done', row);
              // this._scheduledMessageService.getAllScheduleMessages();
              this.getScheduleMessages(false, true);
              // console.log('send approval message now', resp)
              this._tnNotificationService.showCustomInfoByTranslateKey(
                'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.RESCHEDULE_SUCCESS'
              )
            } else {
              resolve(resp);
            }
          },
          err => {
            // console.log(err)
            // this._tnNotificationService.showSystemError()

            if (!reject) {
              console.log(err)
              this._tnNotificationService.showSystemError()
            } else {
              reject(err);
            }
          }
        )
      },
      err => {
        console.log(err)
        this._tnNotificationService.showSystemError()
      },
      undefined, // 2
      1 // is_confirmed
    )
  }

  delaySendScheduleMsg(row): void {
    this._tnNotificationService.showPrompt(
      null,
      'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.DELAY_INPUT_CONFIRM',
      null,
      (delayMins: number) => {
        // console.log('delayMins', delayMins);
        this.callDelaySendScheduleMsgApi(row, delayMins)
      },
      false,
      false,
      true
    )
  }

  cancelScheduleMsg(msgId: string, resolve?: Function, reject?: Function): void {
    this._scheduledMessageService.updateApprovalScheduleMessage(
      msgId,
      this._accountManagerService.companyDomain,
      this._accountManagerService.userId,
      updateResp => {
        // console.log('cancel approval schedule message successfully')

        // this._scheduledMessageService.getAllScheduleMessages();

        // this._tnNotificationService.showCustomInfoByTranslateKey(
        //   'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.CANCEL_SUCCESS'
        // )

        if (!resolve) {
          // this._scheduledMessageService.getAllScheduleMessages();
          this.getScheduleMessages(false, true);

          this._tnNotificationService.showCustomInfoByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.CANCEL_SUCCESS'
          )
        } else {
          resolve(updateResp);
        }
      },
      err => {
        if (!reject) {
          console.log(err)
          this._tnNotificationService.showSystemError()
        } else {
          reject(err);
        }
      },
      3,
      // is_confirmed? under cancel 
    )
  }

  /* Mass Action */
  /* Mass send now */
  async MassUpdateSendScheduleMsgNow() {
    try {
      // let result = await this.bulkSendScheduleMsgNow();
      let result = await this.bulkSendScheduleMsgNow();
      // console.log('mass send done');
      // this._scheduledMessageService.getAllScheduleMessages();
      this.getScheduleMessages(false, true);
    
      this._tnNotificationService.showCustomInfoByTranslateKey(
        'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.SEND_SUCCESS'
      )
    } catch (error) {
      this._tnNotificationService.showSystemError()
    }
  }

  async bulkSendScheduleMsgNow() {
    const scheduleMessageIds = this.getSelectedScheduleMessageIds();
    // console.log(scheduleMessageIds);
    let respPromiseList = _.map(scheduleMessageIds, (id) => {
      return new Promise((resolve, reject) => {
        this._scheduledMessageService.updateApprovalScheduleMessage(
          id,
          this._accountManagerService.companyDomain,
          this._accountManagerService.userId,
          resp => {
            // this._scheduledMessageService.getAllScheduleMessages();
    
            // console.log('send approval message now')
            this._scheduledMessageService.sendScheduleMessage(
              id,
              resp => {
                resolve(resp);
              },
              err => {
                reject(err);
                this._tnNotificationService.showSystemError()
              }
            )
          },
          err => {
            console.log(err)
            this._tnNotificationService.showSystemError()
          },
          undefined, // 2
          1 // is_confirmed
        )
      })
    })

    return Promise.all(respPromiseList);
  }

  /* Mass Delay send */
  async MassDelaySendScheduleMsg() {
    this._tnNotificationService.showPrompt(
      null,
      'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.DELAY_INPUT_CONFIRM',
      null,
      async (delayMins: number) => {
        try {
          await this.bulkDelaySendScheduleMsg(delayMins)

          // console.log('all delay nsg sent');
          
          this.getScheduleMessages(false, true);
          this._tnNotificationService.showCustomInfoByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.RESCHEDULE_SUCCESS'
          )
        } catch (error) {
          this._tnNotificationService.showSystemError()
        }
      },
      false,
      false,
      true
    )

  }

  async bulkDelaySendScheduleMsg(delayMins: number) {
    const selectedMsg = _.filter(this.dataSource.data, { isSelected: true })
    console.log('selectedMsg', selectedMsg);

    let respPromiseList = _.map(selectedMsg, (msg) => {
      return new Promise((resolve, reject) => {
        this.callDelaySendScheduleMsgApi(msg, delayMins, resolve, reject)
      })
    })

    return Promise.all(respPromiseList);
  }

  /* Mass Cancel */
  async MassUpdateCancelScheduleMsg() {
    try {
      await this.bulkCancelScheduleMsg();

      // this._scheduledMessageService.getAllScheduleMessages();
      this.getScheduleMessages(false, true);


      this._tnNotificationService.showCustomInfoByTranslateKey(
        'WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.CANCEL_SUCCESS'
      )
    } catch (error) {
      this._tnNotificationService.showSystemError()
    }
  }

  async bulkCancelScheduleMsg() {
    const scheduleMessageIds = this.getSelectedScheduleMessageIds();
    let respPromiseList = _.map(scheduleMessageIds, (id) => {
      return new Promise((resolve, reject) => {
        this.cancelScheduleMsg(id, resolve, reject);
      })
    })

    return Promise.all(respPromiseList);
  }
}
