/**
 * FSD Configs
 *
 * Created by Shan - 2018-11-14
 */

import { TeamNoteConfig } from '../../configs/models/config';

export const FsdConfig: TeamNoteConfig = {
  HOST: {
    DOMAIN: 'fsd'
  },
  GENERAL: {
    IS_ENABLE_TNC: true,
    PAGE_TITLE: 'Messenger',
    IS_ENABLE_DEBUG: false,
  },
  IMAGES: {
    NOTIFICATION_ICON: 'assets/images/clients/fsd/notification_icon.png',
    FAVICON: 'assets/images/clients/fsd/favicon.png',
    FAVICON_ALERT: 'assets/images/clients/fsd/favicon_alert.png'
  },
  MAP: {

  },
  LOGGER: {
    ENABLE_LOGGER: false
  },
  WEBCLIENT: {
    GENERAL: {
      IS_USE_WEBSOCKET: true,
      IS_ENABLE_MULTI_CHATROOM_MODE: true,
      ENABLE_MULTI_ACCOUNTS: true,
    },
    CHATROOM: {
      IS_ENABLE_SEARCH : true,
      IS_ENABLE_MARKDOWN_INPUT: true,
      IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD: false,
      ENABLE_ENCRYPTED_MESSAGE: true,
    },
    NEWS: {
      IS_ENABLE_CONTENT_PREVIEW: true
    }
  }
};
