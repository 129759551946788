import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { TeamnoteApiService } from '../api/teamnote-api.service';
import { TeamNoteGeneralConstant } from '../constants/general.constant';
import { TeamNoteLocalStorageKeyConstants } from '../constants/local-storage-key.constant';
import { PageUrlConstant } from '../constants/page-url.constant';
// import { WebclientLoginService } from '../login/webclient-login.service';
import { IdleTimeoutService } from '../utilities/idle-timeout/idle-timeout.service';
import { LocalStorageManagerService } from '../utilities/local-storage/local-storage-manager.service';
import { LoggerService } from '../utilities/logger/logger.service';
import { NotificationCenterService } from '../utilities/notification-center/services/notification-center.service';
import { TnDialogService } from '../utilities/tn-dialog/tn-dialog.service';
import { TnLoaderService } from '../utilities/tn-loader/tn-loader.service';
import { WatermarkService } from '../utilities/watermark/watermark.service';
import { AccountManagerService } from './services/account/account-manager.service';
import { DataManagerService } from './services/data/data-manager.service';
import { ModuleManagerService } from './services/module/module-manager.service';
import { SocketService } from './services/socket/socket.service';
import { AccountService } from '../account/account.service';
import { BaseRoutingService } from '../routing/base-routing.service';
import { UserContactService } from './services/data/user-contact/user-contact.service';

var addDays = require('date-fns/add_days');
import { TnNotificationService } from '../utilities/tn-notification/tn-notification.service';
import { SideNavService } from '../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../constants/module.constant';
import { QuestionnaireService } from './questionnaire/questionnaire.service';
import { JobReportService } from './job-report/job-report.service';
import { DateService } from '../utilities/date/date.service';
import { WorkflowService } from './workflow/services/workflow.service';
import { TeamnoteConfigService } from '../configs/teamnote-config.service';
import {TeamNoteApiConstant} from '../constants/api.constant';
import { ImportantUsers } from '../constants/user.constant';
import { BehaviorSubject } from 'rxjs';
import { EmojiService } from '../utilities/emoji/emoji.service';
import { MultiChatRoomService } from './chat/multi-chat-room.service';
import { WebclientLoginResponse } from '../login/models/webclient-login-response';

import { ChatMessageService } from './services/data/messages/chat-message/chat-message.service';
import { MessagesService } from './services/data/messages/messages.service';
import { NewsMessageService } from './services/data/messages/news-message/news-message.service';
import { WebclientLoginService } from '../login/webclient-login.service';
import { ExerciseService } from './exercise/exercise.service';
import { ScheduledMessageService } from './schedule-message/schedule-message.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class WebclientService {

  private _isConnected: boolean = false;
  private _isReconnecting: boolean = false;
  private _isReLoggingin: boolean = false;

  isDevMode: boolean = false;
  chatRoomTestMessageCounts: number[] = [10, 50, 100, 500, 1000, 5000, 10000];

  reconnectingTimeout: any = null;
  reconnectingCount: number = 0;
  reconnectingThreshold: number = 20;
  reconnectingInterval: any = null;

  enable_important_users: boolean;
  enable_message_delete: boolean;

  display_user_fields_in_chat: any;
  enable_message_read_ticks: boolean;
  enable_message_star: boolean;

  reconnectionTimer: any = null;
  reconnectionStopCount: number = 0;
  reconnectionStopCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
  isPageVisible: boolean = true;
  isPageVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  reconnectionTimerByAccount: any = {};
  reconnectingCountByAccount: any = {};

  constructor(
    private _accountManagerService: AccountManagerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _socketService: SocketService,
    private _dataManagerService: DataManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _tnLoaderService: TnLoaderService,
    private _tnDialogService: TnDialogService,
    private _teamnoteApiService: TeamnoteApiService,
    private _loggerService: LoggerService,
    private _idleTimeoutService: IdleTimeoutService,
    private _notificationCenterService: NotificationCenterService,
    private _accountService: AccountService,
    private _baseRoutingService: BaseRoutingService,
    private _tnNotificationService: TnNotificationService,
    private _sideNavService: SideNavService,
    private _watermarkService: WatermarkService,
    private _questionnaireService: QuestionnaireService,
    private _jobReportService: JobReportService,
    private _dateService: DateService,
    private _workflowService: WorkflowService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _userContactService: UserContactService,
    private _emojiService: EmojiService,
    private _multiChatRoomService: MultiChatRoomService,

    private _messageService: MessagesService,
    private _chatMessageService: ChatMessageService,
    private _newsMessageService: NewsMessageService,
    private _webclientLoginService: WebclientLoginService,
    private _exerciseService: ExerciseService,
    private _cookieService: CookieService,
    private _scheduledMessageService: ScheduledMessageService,

  ) {
    this._loggerService.debug("Setting socketService's functions content, avoid circular dependency");
    this._socketService.customConnectCallback = () => this.onConnectSuccessCallback();
    this._socketService.customErrorCallback = (error) => this.socketErrorCallback(error);
    this._socketService.unknownMessageCallback = (frame, isBinding) => this._dataManagerService.receiveMessageCallback(frame, isBinding);
    this._socketService.unknownPresenceCallback = (frame, isBinding) => this._dataManagerService.receivePresenceCallback(frame, isBinding);
    
      /* for multi account websocket connection */
      this._socketService.initRosterByAccount = (userId) => this._dataManagerService.initRosterByAccount(userId);
      this._socketService.initMessagesByAccount = (userId) => this._messageService.initMessagesByAccount(userId);
      this._socketService.initReconnectionTimerByAccount = (accUserid) => this.initReconnectionTimerByAccount(accUserid);
      this._socketService.initReconnectionCountByAccount = (accUserid) => this.initReconnectionCountByAccount(accUserid);
      this._socketService.customErrorCallback_v2 = (error, accUserId) => this.socketErrorCallback_v2(error, accUserId);
      this._socketService.unknownMessageCallback_v2 = (frame, isBinding, userId) => this._dataManagerService.receiveMessageCallback_v2(frame, isBinding, userId);
      this._socketService.unknownPresenceCallback_v2 = (frame, isBinding, userId) => this._dataManagerService.receivePresenceCallback_v2(frame, isBinding, userId);
      this._dataManagerService.switchToTargetAccountByUserId = (accUserId) => this.switchToTargetAccountByUserId(accUserId);

    this._loggerService.debug("Setting teamnoteApiService.logoutWebClient function content, avoid circular dependency");
    this._teamnoteApiService.logoutWebClient = (isDisconnectByServer) => this.onLogoutWebClient(isDisconnectByServer);
  }

  tnDebugger() {
    return this._dataManagerService.tnDebugger();
  }

  onLoginSuccess() {
    this.clearReconnectingCounter();
    this._loggerService.debug("Login Success!");
    this._accountManagerService.storeLoginResponse(this._accountService.fullLoginResponse);
    let data = this._accountManagerService.fullLoginResponse;

    let originalUserId = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);

    this._loggerService.debug("Set latest session_token, user_id, message_recall_period to Cookies");
    let oneMonthFromNow = addDays(new Date(), 30);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN, data.session_token, oneMonthFromNow);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID, data.user.user_id, oneMonthFromNow);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD, data.user.message_recall_period, oneMonthFromNow);

    if (originalUserId != null && originalUserId !== data.user.user_id) {
      this._loggerService.debug("Newly logged in user is not the same as previous user, clear local settings...");
      this.removeLocalSettings();
    }

    this._loggerService.debug("Set User ID in accountManagerService");
    this._accountManagerService.userId = data.user.user_id;

    this._loggerService.debug("Setting up TeamNote Date format...");
    this._dateService.initTnDateFormats();

    this._loggerService.debug("Installing Emoji set...");
    this._emojiService.installAppEmojiSet();

    let setupSideNav = function() {
      this._loggerService.debug("Setting up side nav...");
      this._sideNavService.setUpSideNav();
    }
    this._loggerService.debug("Setting available modules...");
    this._moduleManagerService.setModules(data.user.module, setupSideNav.bind(this));

    // this._loggerService.debug("Setting up side nav...");
    // this._sideNavService.setUpSideNav();


    // Idle Timeout
    if (data.user.session_timeout != -1) {
      this._loggerService.debug("Session timeout is enabled");
      this._idleTimeoutService.initIdleTimeout(data.user.session_timeout);
    }

    console.log('this._accountService.checkIfRootUserModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)', this._accountService.checkIfRootUserModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN));
    // TODO: store other Logins Response(e.g, the extra accounts that bind to exercise(other company domain))
    // TODO: connect to websocket per account
    // if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)) {
    if (this._accountService.checkIfRootUserModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)) {
      this._exerciseService.getAvailableExerciseUsers(
        data.session_token, 
        () => {
          console.log('loginSingleAccountCallback');

          // this._multiChatRoomService.initMultiChatRooms(false);
          this.connectWebSocketForMainAccount(data);
        }
      );
    } else {
      this._loggerService.debug("Initializing multi chatroom panels...");
      // this._multiChatRoomService.initMultiChatRooms(false);

      this.connectWebSocketForMainAccount(data);
    }
  }

  connectWebSocketForMainAccount(data): void {
    // connect websocket for single account
    this._loggerService.debug("Connecting to web socket...");
    this._socketService.initCallbacksCollectionByAccount(data.user.user_id)
    this._socketService.connectToWebSocket(data.user.user_id, data.session_token);
  }

  removeLocalSettings() {
    let localSettingsKeys = TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES;
    for (let i in localSettingsKeys) {
      this._localStorageManagerService.removeCookiesByKey(localSettingsKeys[i]);
    }
    this._loggerService.debug("Remove local settings in cookies: " + _.values(localSettingsKeys).join(", "));
  }

  clearExtAuthCookies(): void {
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_TYPE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.AUTH_NAME);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.EXTERNAL_AUTH.OAUTH.OAUTH_TOKEN);
  }

  onConnectSuccessCallback() {
    this._loggerService.debug("Web socket is connected successfully, resetting local variables");
    this._isConnected = true;
    this._isReconnecting = false;
    this._isReLoggingin = false;
    this.clearReconnectingCounter();

    this._loggerService.debug("Getting modules initial contents...");

    console.log('onConnectSuccessCallback account???', _.cloneDeep(this._accountManagerService));
    // if (this._accountManagerService.isEnableMultiAccountLogin()) {
    //   return;
    // }

    /* main account App Config settings(i.e, settings of '.' domain) */
    this.getAllModuleInitialContent();
    this.getModuleGlobalConfigs(null);
  }

  getModuleGlobalConfigs(callback: Function, isMultiAccMode?: boolean): void {
    this._teamnoteApiService.callApi(
      TeamNoteApiConstant.USER_FIELDS,
      {},
      (resp) => {
        console.log('getModuleGlobalConfigs', resp);

        if (!isMultiAccMode) {
          /* main account app config settings */
          this.display_user_fields_in_chat = resp.global && resp.global.display_user_fields_in_chat
          this.enable_message_read_ticks = resp.global && resp.global.enable_message_read_ticks
          this.enable_message_star = resp.global && resp.global.enable_message_star
          this.enable_message_delete = resp.global && resp.global.enable_message_delete
  
          this.enable_important_users = resp.global && resp.global.enable_important_users
          // if enable_important_users then call api to get all important users
          this.enable_important_users && this.getImportantUsers()
        } else {
          console.log('enable_important_users', this.enable_important_users);
          // follow the main account app config settings except for important users
          // (will not overwrite the existing main account settins except for important users)
          this.enable_important_users && this.getImportantUsers(callback)
        }

        // if (callback) {
        //   callback();
        // }
      },
      () => {}
    )
  }

  onLogoutWebClient(isDisconnectedByServer: boolean) {
    this._loggerService.debug("Logging out webclient..., isDisconnectedByServer = " + isDisconnectedByServer);

    // TODO: set session storage key 'domain' again?
    this._moduleManagerService.resetModuleConfig();
    this._accountService.init();

    // Call logout to invalidate session
    if (!isDisconnectedByServer) {
      this._teamnoteApiService.callApi(TeamNoteApiConstant.LOGOUT, {
        device_token: this._localStorageManagerService.getDeviceToken(),
      }, (resp) => {
        this._loggerService.debug('Logout success');
      }, () => {
        this._loggerService.debug('Failed to logout');
      });
    }

    // Clear all route related cookies
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE);
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);

    // Remove session token and refresh token
    this._loggerService.debug("Remove session token and password in cookies");
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN);
    this._localStorageManagerService.removeLocalStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.PASSWORD);
    this._localStorageManagerService.removeSessionStorageByKey(TeamNoteLocalStorageKeyConstants.SESSION.REFRESH_TOKEN);

    if (!isDisconnectedByServer) {
      this._loggerService.debug("User logout manually, remove local settings, user_id, message_recall_period in cookeis");

      // if manually logout, delete local settings & user_id
      this.removeLocalSettings();

      // if manually logout, delete device token
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN);

      // remove user name by config
      if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_REMOVE_SESSION_USER_NAME) {
        this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_NAME);
      }

      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID);
      this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD);

      // if manually logout, delete all cookies under TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT
      // if (this._accountManagerService.isEnableMultiAccountLogin()) {
      // if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.ENABLE_MULTI_ACCOUNTS) {
        // remove prev exercise domain cookie
        // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.PREV_EXERCISE_ROLE + `_${this._accountService.userId}`);
        
        // remove all cookies which are under TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT
        // _.each(this._cookieService.getAll(), (cookie, key) => {
        //   const multiAccountCookieKeyPattern = TeamNoteLocalStorageKeyConstants.PREFIX +'MA_';
        //   if (key.startsWith(multiAccountCookieKeyPattern)) {
        //     this._localStorageManagerService.removeCookiesByKey(key, true);
        //   }
        // })
      // }

      // Remove ext auth related cookies
      this.clearExtAuthCookies();

      this._loggerService.debug("Resetting login info in webclientLoginService...");
      // remove user_name and password in loginService
      // this.acc.resetLoginInfo();

      // remove all notifications
      this._notificationCenterService.clearAllNotifications();
    } else {
      this._loggerService.debug("Disconnected by server, setting disconnect time...");
      // if disconnected by server, save disconnet time = last active time
      this._socketService.setDisconnectTime();
      
      // if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.ENABLE_MULTI_ACCOUNTS) {
      // if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)) {
        _.each(this._accountManagerService.allLoggedInAccountRes, (acc, user_id) => {
          this._socketService.setDisconnectTime_v2(user_id);
        })
      // }
    }

    // Close all modals
    this._loggerService.log("Closing all opened dialogs...");
    this._tnDialogService.closeAllDialogs();

    // Clear watermark setting
    this._loggerService.log("Clear watermark setting");
    this._watermarkService.initWatermarkSetting();

    // Reset side nav
    this._loggerService.debug("Resetting side navs");
    this._sideNavService.resetSideNavs();

    // reset chat loaded status
    this._loggerService.debug("Resetting chat loaded status");
    this._dataManagerService.updateIsChatLoaded(false);

    // reset info in account manager
    this._loggerService.debug("Resetting info in accountManagerService");
    this._accountManagerService.onLogout();

    // set isConnected = false
    this._loggerService.debug("Set webclientService._isConnected to false");
    this._isConnected = false;

    // Reset is need reconnect (to handle case where it was reconnecting before, but received access refuse and cannot relogin due to expired token. We need to reset it when we LOGOUT)
    this._socketService.realSetIsNeedReconnectFalse();

    // Disconnect socket
    this._loggerService.debug("Disconneting web socket...");
    this._socketService.disconnectSocket(isDisconnectedByServer);

    // Clear all data in data manager
    this._loggerService.debug("Resetting all data in dataManagerService");
    this._dataManagerService.resetAllData();


    /* 
      ENABLE_MULTI_ACCOUNTS 
      1. Reset is need reconnect for ws connection of all accounts
      2. Disconnect socket for all accounts
      3. reset all Multi account data (roster, presence, messages)
      4. clear all logged in multi account
    */
    _.each(this._cookieService.getAll(), (cookie, key) => {
      const multiAccountCookieKeyPattern = TeamNoteLocalStorageKeyConstants.PREFIX +'MA_';
      if (key.startsWith(multiAccountCookieKeyPattern)) {
        this._localStorageManagerService.removeCookiesByKey(key, true);
      }
    })

    // if (this._teamnoteConfigService.config.WEBCLIENT.GENERAL.ENABLE_MULTI_ACCOUNTS) {
      this._socketService.clearAllSocketConnections();
    // }

    // Clear all multi accounts' message & presence data
    this._dataManagerService.resetAllMultiAccountData();

    // Clear all multi accounts' login response record
    this._accountManagerService.onLogoutMultiAccounts();

    this._loggerService.debug("Resetting all data in MultiChatRoomService");
    this._multiChatRoomService.initMultiChatRooms(true);

    this._loggerService.debug("Resetting active exercise");
    this._exerciseService.updateActiveExercise(null);

    // this._moduleManagerService.resetModuleConfig();

    // go to login page
    this._loggerService.log("Logout success! Redirecting to /login");
    this._baseRoutingService.goToLoginPage();
  }

  clearReconnectingCounter(): void {
    this.reconnectingCount = 0;
    clearInterval(this.reconnectingTimeout);
  }

  updatePageVisibleSubject(val: boolean): void {
    this.isPageVisible$.next(val);
  }

  updateReconnectionStopCountSubject(val: number): void {
    if (this.reconnectionStopCount !== null) {
      this.reconnectionStopCount = val
      this.reconnectionStopCount$.next(val);
    }
  }

  // Socket
  socketErrorCallback(error) {
    this._loggerService.error("Socker error callback, checking if need reconnect or relogin");
    let isAccessRefused = false;
    if (typeof error.body != 'undefined') {
      isAccessRefused = error.body.indexOf(TeamNoteGeneralConstant.ACCESS_REFUSED_ERROR) !== -1;
    }
    // Debug
    // isAccessRefused = true;
    // console.log('isAccessRefused?', isAccessRefused, 'isPageVisible?', this.isPageVisible)
    if (!isAccessRefused) {
      this._loggerService.debug("Socket error is no Access Refused.");
      if (this._accountManagerService.userId && this._accountManagerService.accessToken) {
        this._loggerService.debug("User ID and session token exists, webclient was connected.");
        this._loggerService.debug("Disconnect from web socket and try to reconnect every 5 seconds...");
        // if it was connected & not refused by server, try to reconnect 
        // console.log('try to reconnect socket, is ws connected well?', this._socketService.getClient());
        this._socketService.disconnectSocket(this._isConnected);
        if (this._isConnected) {
          this._socketService.setIsNeedReconnectTrue();
          this._socketService.setDisconnectTime();
        }
        // this._socketService._isNeedReconnect = this._isConnected;
        // this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

        if (this.reconnectingCount >= this.reconnectingThreshold) {
          this._loggerService.debug("Logout after 20 retries...");
          this.clearReconnectingCounter();
          this.onLogoutWebClient(true);
          return;
        }

        clearTimeout(this.reconnectionTimer);
        this.reconnectionTimer = setTimeout(() => {
          this._loggerService.debug("Reconnecting to web socket...");
          if (!this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
            this.reconnectingCount++;
          }

          this.reconnectionStopCount++
          this.updateReconnectionStopCountSubject(this.reconnectionStopCount);
          // console.log('reconnectionCount:', this.reconnectionStopCount)

          console.warn(this.reconnectingCount);
          
          // console.log('try to reconnect to WebSocket')
          this._socketService.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
        }, 5000);
      } else {
        this._loggerService.debug("User ID and session token don't exist, it was logged out before.");
        this._loggerService.debug("Call logout again.");
        this.onLogoutWebClient(true);
      }
    } else {
      console.log('Socket error is Access refused', isAccessRefused);
      this._loggerService.debug("Socket error is Access refused");
      this.clearReconnectingCounter();
      if (!this._isReLoggingin) {
        this._loggerService.debug("User is not in the middle of re-logging in, try to relogin webclient");
        this._isReLoggingin = true;
        // access refused = token expired, try to relogin if all login info exist
        // console.log('try to relogin webclient');
        this._teamnoteApiService.reLogin(
          () => {
            this._loggerService.debug("Relogin success, call login success process again");
            this.onLoginSuccess();
          },
          () => {
            this._loggerService.error("Relogin failed after socket error, logout webclient");
            this.onLogoutWebClient(true);
            this._tnNotificationService.showAlert(
              null,
              "GENERAL.SESSION_TIMEOUT_MSG",
              null,
              "GENERAL.CONFIRM",
              () => {
                location.reload();
              }
            );
          }
        );
      } else {
        this._loggerService.error("User failed socket connection twice already, logout webclient");
        // If user is already tring to re login (i.e. user failed twice), logout
        this.onLogoutWebClient(true);
      }
    }
    return false;
  }

  // Simulate socket connectivity
  disconnectMultiAccountSockets() {
    const accounts = this._accountManagerService.allLoggedInAccountRes;
    _.each(accounts, (acc, userId) => {
      this._socketService.disconnectSocket_v2(true, userId);
      this._socketService.setIsNeedReconnectTrue_v2(userId);
      this._socketService.setDisconnectTime_v2(userId);
    })
  }
  simulateDisconnect() {
    this._loggerService.debug("To simulate disconnect, disconnecting web socket");
    this._socketService.disconnectSocket(true);
    this._socketService.setIsNeedReconnectTrue();
    this._socketService.setDisconnectTime();
  }
  simulateReconnect() {
    console.log('To simulate reconnect, connect web socket again')
    this._loggerService.debug("To simulate reconnect, connect web socket again");
    this._socketService.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
  }

  enableDevMode(): void {
    this.isDevMode = true;
  }


  /**
   * Get All intial contents by module
   *
   * @memberof WebclientService
   */
  getAllModuleInitialContent(): void {
    _.each(this._moduleManagerService.modules, (moduleKey) => {
      this.getInitialContentByModuleKey(moduleKey);
    });
  }


  /**
   * Get separate initial content by module, perform all follow up action in module itself
   *
   * @param {string} key
   * @memberof WebclientService
   */
  getInitialContentByModuleKey(key: string): void {
    switch (key) {
      case ModuleKeyDefinition.WATERMARK:
        this._watermarkService.handleInitialWatermarkContent();
        break;
      case ModuleKeyDefinition.QUESTIONNAIRE:
        this._questionnaireService.handleInitialQuestionnaireContent();
        break;
      case ModuleKeyDefinition.STORE_REPORT:
        this._jobReportService.handleInitialStoreReportContent(this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.JOB_DISPATCH));
        break;
      case ModuleKeyDefinition.WORKFLOW:
        this._workflowService.handleInitialWorkflowContent();
        break;
    }
  }

  checkIfEnableMessageReadTicks(): boolean {
    return this.enable_message_read_ticks;
  }

  checkIfEnableMessageStar(): boolean {
    return this.enable_message_star;
  }

  getImportantUsers(callback?: Function): void {
    this._teamnoteApiService.callApi(
      TeamNoteApiConstant.IMPORTANT_USERS.GET,
      {},
      (resp: ImportantUsers) => {
        this._userContactService.storeImportantUsers(resp)

        // sync the vip display changes for chat list
        this._userContactService.updateImportantUsers(this._userContactService.getImportantUsers())

        if (callback) {
          callback()
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      }
    )
  }

  checkIfEnableImportantUsers(): boolean {
    return this.enable_important_users;
  }

  checkIfEnableMessageDelete(): boolean {
    return this.enable_message_delete;
  }

  initReconnectionTimerByAccount(accUserId: string): void {
    if (!this.reconnectionTimerByAccount[accUserId]) {
      this.reconnectionTimerByAccount[accUserId] = null;
    }
  }

  clearReconnectionTimer(): void {
    _.each(_.keys(this.reconnectionTimerByAccount), (accUserId) => {
      clearTimeout(this.reconnectionTimerByAccount[accUserId]);
      this.reconnectionTimerByAccount[accUserId] = null;
    })
  }

  initReconnectionCountByAccount(accUserId: string): void {
    if (!this.reconnectingCountByAccount[accUserId]) {
      this.reconnectingCountByAccount[accUserId] = 0;
    }
  }

  clearReconnectionCount(): void {
    _.each(_.keys(this.reconnectingCountByAccount), (accUserId) => {
      this.reconnectingCountByAccount[accUserId] = 0;
    })
  }

  checkIfAllReconnectCountHitReconnectingThreshold(): boolean {
    return _.every(this.reconnectingCountByAccount, (count) => count >= this.reconnectingThreshold);
  }
  
  // for multi-account
  async socketErrorCallback_v2(error, accUserId: string) {
    // this._loggerService.error("Socker error callback, checking if need reconnect or relogin");
    console.log(`Account ${accUserId} Socker error callback, checking if need reconnect or relogin`);
    let isAccessRefused = false;
    if (typeof error.body != 'undefined') {
      isAccessRefused = error.body.indexOf(TeamNoteGeneralConstant.ACCESS_REFUSED_ERROR) !== -1;
    }
    // Debug
    // isAccessRefused = true;
    // console.log('isAccessRefused?', isAccessRefused, 'isPageVisible?', this.isPageVisible)
    if (!isAccessRefused) {
      const targetAccount = this._accountManagerService.getLoggedInAccounResByUserId(accUserId)
      // this._loggerService.debug("Socket error is no Access Refused.");
      console.log(`Account ${accUserId} Socket error is no Access Refused.`);
      if (targetAccount.user.user_id && targetAccount.session_token) {
        console.log(`Account ${accUserId} - User ID and session token exists, webclient was connected.`);
        console.log(`Account ${accUserId} - Disconnect from web socket and try to reconnect every 5 seconds...`);

        this._socketService.disconnectSocket_v2(this._isConnected, accUserId);
        if (this._isConnected) {
          this._socketService.setIsNeedReconnectTrue_v2(accUserId);
          this._socketService.setDisconnectTime_v2(accUserId);
        }

        console.log(`Account ${accUserId}, reconnectingCountByAccount`, this.reconnectingCountByAccount[accUserId]);
        if (this.checkIfAllReconnectCountHitReconnectingThreshold()) {
          this._loggerService.debug(`[Multi-Account] Logout after 20 retries...`);

          this.clearReconnectionTimer();
          this.clearReconnectionCount();

          // this.disconnectMultiAccountSockets();
          
          this.onLogoutWebClient(true);
          return;
        }

        // let exercises = await this._teamnoteApiService.syncGetAvailableExercise();
        // console.log('exercises', exercises);

        if (accUserId in this.reconnectionTimerByAccount) {
          clearTimeout(this.reconnectionTimerByAccount[accUserId]);
        }

        this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
        this._exerciseService.setExerciseLoadingStatus(accUserId, true);

        // console.log('ready to reconnect, start to loading');

        this.reconnectionTimerByAccount[accUserId] = setTimeout(() => {
          // this._loggerService.debug(`[Account ${accUserId}] Reconnecting to web socket...`);
          console.log(`[Account ${accUserId}] Reconnecting to web socket...`);
          if (!this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_AUTO_EXTEND_SESSION) {
            this.reconnectingCountByAccount[accUserId]++;
            console.log(`will count reconnectingCount for account ${accUserId}: ${this.reconnectingCountByAccount[accUserId]}`);
          }

          // this.reconnectionStopCount++
          // this.updateReconnectionStopCountSubject(this.reconnectionStopCount);
          // console.log('reconnectionCount:', this.reconnectionStopCount)

          // console.warn(this.reconnectingCount);
          
          console.log(`[Account ${accUserId}] try to reconnect to WebSocket`);
          this._socketService.connectToWebSocket_v2(targetAccount.user.user_id || accUserId, targetAccount.session_token);
        }, 5000);

      } else {
        this._loggerService.debug("User ID and session token don't exist, it was logged out before.");
        this._loggerService.debug("Call logout again.");
        this.onLogoutWebClient(true);
      }
    } else {
      // console.log(`account ${accUserId} Socket error is Access refused, need regenerate the user's access_token to establish a new webSocket connection`);
      // this._loggerService.debug(`account ${accUserId} Socket error is Access refused, need regenerate the user's access_token to establish a new webSocket connection`);
      

      // this._socketService.disconnectSocket_v2(this._isConnected, accUserId);
      // if (this._isConnected) {
      //   this._socketService.setIsNeedReconnectTrue_v2(accUserId);
      //   this._socketService.setDisconnectTime_v2(accUserId);
      // }

      // if (accUserId in this.reconnectionTimerByAccount) {
      //   clearTimeout(this.reconnectionTimerByAccount[accUserId]);
      // }

      // this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
      // this._exerciseService.setExerciseLoadingStatus(accUserId, true);

      // this.reconnectionTimerByAccount[accUserId] = setTimeout(() => {
      //   console.log(`[Account ${accUserId}] try to reconnect to WebSocket`);
      //   /* try to relogin target exercise user */
      //   const exercise = this._exerciseService.getExerciseByExerciseUserId(accUserId);
      //   this._exerciseService.loginTargetExercise(exercise);
      //   // this._socketService.connectToWebSocket_v2(targetAccount.user.user_id || accUserId, targetAccount.session_token);
      // }, 5000);
      
      console.log(`account ${accUserId} Socket error is Access refused, need logout & relogin the main account`);
      this._loggerService.debug(`account ${accUserId} Socket error is Access refused, need logout & relogin the main account`);

      this.clearReconnectionTimer();
      this.clearReconnectionCount();

      /* 
        if any accounts' websocket hit 'Access refused' Error, then states that 
        either the main account's access token (session token) has expired, or one of the exercise account's access tokens (session tokens) has expired,
        TODO: logout the main account & reload
      */

      if (!this._isReLoggingin) {
        this._loggerService.debug("the main account is not in the middle of re-logging in, try to relogin webclient");
        this._isReLoggingin = true;
        // access refused = token expired, try to relogin if all login info exist
        // console.log('try to relogin webclient');
        this._teamnoteApiService.reLogin(
          () => {
            this._loggerService.debug("Relogin success, call login success process again");
            this.onLoginSuccess();
          },
          () => {
            this._loggerService.error("Relogin failed after socket error, logout webclient");
            this.onLogoutWebClient(true);
            this._tnNotificationService.showAlert(
              null,
              "GENERAL.SESSION_TIMEOUT_MSG",
              null,
              "GENERAL.CONFIRM",
              () => {
                location.reload();
              }
            );
          }
        );
      } else {
        this._loggerService.error("is re-logging the main account");
        // If user is already tring to re login (i.e. user failed twice), logout
        // this.onLogoutWebClient(true);
      }
    }
    
    return false;
  }

  switchAccountGlobalConfig(callback: Function) {
    // this._loggerService.debug("Web socket is connected successfully, resetting local variables");
    // this._isConnected = true;
    // this._isReconnecting = false;
    // this._isReLoggingin = false;
    // this.clearReconnectingCounter();

    console.log('switchAccountGlobalConfig');
    // this._loggerService.debug("Getting modules initial contents...");
    this.getAllModuleInitialContent(); // should be no need to create a getAllModuleInitialConten_v2
    this.getModuleGlobalConfigs(callback, true);
  }

  switchToTargetAccountByUserId(accUserId: string): void {
    // to do for switch account
    const accUserContact = this._userContactService.getUserContactByUserIdAndAccountUserId(accUserId, accUserId)
    /* 
      accUserContact should be empty since not yet receive the target exercise user's userContact frame to insertOrUpdateUserContact_v2 
      it will update immediately after receive the target exercise user's userContact.
    */
    this._accountManagerService.switchAccountByUserId(accUserId, accUserContact)

    const exercise = this._exerciseService.getExerciseByExerciseUserId(accUserId);
    let oneWeekFromNow = addDays(new Date(), 7);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.PREV_EXERCISE_ROLE + `_${this._accountService.userId}`, JSON.stringify(exercise), oneWeekFromNow);
    /* 
      // apply modules and /module/config settings of target company_domain
      this._moduleManagerService.switchAccountModule(() => 
        this._sideNavService.setUpSideNav(() => {
          // unread message update callback is called after side menu setting
          this._chatMessageService.updateUnreadMessageSubject_v2(this._accountManagerService.userId)
          // this._newsMessageService.updateUnreadNewsSubject_v2(this._accountManagerService.userId);
        })
      )

      // call after set modules in class ModuleManagerService
      this.switchAccountGlobalConfig(this.switchAccountDataManagerData.bind(this)); 
    */

    this._moduleManagerService.switchAccountModule(() => {
      this._sideNavService.setUpSideNav(() => {
        // unread message update callback is called after side menu setting
        this._chatMessageService.updateUnreadMessageSubject_v2(this._accountManagerService.userId)
        // this._newsMessageService.updateUnreadNewsSubject_v2(this._accountManagerService.userId);
      })

      if (this.enable_important_users) {
        this.switchAccountGlobalConfig(this.switchAccountDataManagerData.bind(this)); 
      } else {
        this.switchAccountDataManagerData();
      }
    })

    /* apply modules and /module/config settings of '.' domain */
    // this._chatMessageService.updateUnreadMessageSubject_v2(this._accountManagerService.userId);
    // this.switchAccountDataManagerData();


    // if (this.enable_important_users) {
    //   this.switchAccountGlobalConfig(this.switchAccountDataManagerData.bind(this)); 
    // } else {
    //   this.switchAccountDataManagerData();
    // }

    // this._socketService.switchActiveSocketConnection(this._accountManagerService.userId)
  }

  switchAccountDataManagerData(): void {
    this._dataManagerService.switchAccountRoster(this._accountManagerService.userId)
    this._dataManagerService.switchAccountPresence(this._accountManagerService.userId)
    this._dataManagerService.switchAccountMessages(this._accountManagerService.userId)
    // const exerciseName = this._exerciseService.getExerciseByExerciseUserId(this._accountManagerService.userId); 
    this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.EXERCISE.LOGIN_EXERCISE_SUCCESS");
  }
}
