<!-- 'background-color': item.webview?.navbar_color ? item.webview.navbar_color : '' -->
<div class="nav-item-wrapper" *ngFor="let item of sideNavItems">
  <ng-container *ngIf="item.isDisplay">
    <!-- Module type nav item -->
    <a
      *ngIf="item.type === 'module'"
      class="nav-item"
      [routerLink]="item.link"
      (click)="changeRoute(item)"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      [ngClass]="{
        'nav-item-active': item.isActive || item.key == currentNavItemKey,
        'nav-item-expanded': item.key === 'chat' && isShowMultiChatroomList && isEnableWebCommanderView
      }"
      [ngStyle]="{ 'pointer-events': item.link ? 'unset' : 'none' }"
    >
      <!-- <ng-container> -->
        <div class="nav-label" [ngClass]="{ 'margin-placeholder': isEnableWebCommanderView }">
          <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
          <i class="label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
          <span class="label-title" *ngIf="item.name">{{ item.name | translate }}</span>
          <span class="label-title" *ngIf="item.nameStatic">{{ item.nameStatic }}</span>
        </div>

        <div class="nav-count unread-bubble" [ngClass]="{ 'margin-placeholder': isEnableWebCommanderView }" *ngIf="item.count > 0">
          {{ item.count }}
        </div>

        <div class="unapprove-msg-count" *ngIf="item.key === MODULE_KEYS.SCHEDULE_MESSAGE && unapprovedScheduleMessages.length > 0">
          {{ unapprovedScheduleMessages.length }}
        </div>

        <button
          mat-icon-button
          color="white"
          class="toggle-icon-container clickable"
          [ngClass]="{ 'margin-auto': isEnableWebCommanderView }"
          (click)="onOpenMultiChatroomList($event)"
          *ngIf="isEnableWebCommanderView && item.key === 'chat'"
        >
          <i
            class="fa fa-fw"
            [ngClass]="{ 'fa-angle-down': !isShowMultiChatroomList, 'fa-angle-up': isShowMultiChatroomList }"
          ></i>
        </button>
      <!-- </ng-container> -->
    </a>

    <!-- For displaying multi-chatroom list purpose -->
    <ng-container *ngIf="isEnableWebCommanderView">
      <div
        class="sub-navs-container"
        *ngIf="isShowMultiChatroomList && item.key === MODULE_KEYS.CHAT"
      >
        <!-- <ng-container *ngIf="multiChatrooms.length > 0; else addPlaceholder"> -->
        <div
          class="sub-nav-item clickable"
          *ngFor="let panel of multiChatRoomsPanels"
          [matTooltip]="panel.name"
          (click)="onEnterTargetMultiChatroomPanel($event, panel, item)"
        >
          <div class="sub-nav-label" [ngClass]="{ 'sub-nav-label-active': activeMultiChatroomPanel ? panel.panelId === activeMultiChatroomPanel.panelId : false }">
            <i class="fa fa-columns sub-nav-icon"></i>
            <span class="sub-nav-name">{{ panel.name }} ({{ panel.multiChatrooms.length }} {{'WEBCLIENT.CHAT.CHAT_GROUP.MULTICHAT_GROUPS_COUNT' | translate}})</span>
          </div>
        </div>
        <div [matTooltip]="('WEBCLIENT.CHAT.ADD_PANEL' | translate)" class="sub-nav-item clickable" (click)="onNewMultiChatroomPanel($event, item)">
          <div class="sub-nav-label add-panel">
            <div class="add-green-icon text-color-primary">
              <i class="fa fa-plus-square-o fa-2"></i>
            </div>
            <span>{{ 'WEBCLIENT.CHAT.ADD_PANEL' | translate }}</span>
          </div>
        </div>
        <div class="loading-masker" *ngIf="!isChatLoaded">
          <i class="fa fa-spinner fa-pulse loading-icon fa-fw"></i>
        </div>
      </div>
    </ng-container>

    <!-- <ng-container *ngIf="item.key === MODULE_KEYS.SCHEDULE_MESSAGE">
      <div class="sub-navs-container">
        <div class="sub-nav-item clickable" (click)="onEnterUnapprovalScheduleMessagePage($event, item)">
          <div class="sub-nav-label add-panel" [ngClass]="{ 'sub-nav-label-active': isUnapprovalMessageView }">
            <div class="schedule-message-icon text-color-primary">
              <img class="label-img" src="assets/images/side_nav/chat.png" />
            </div>
            <span class="unapprove-msg-content">{{'WEBCLIENT.SCHEDULE_MESSAGE.SCHEDULED_MESSAGE_TITLE' | translate }}</span>
            <div class="unapprove-msg-count" *ngIf="unapprovedScheduleMessages.length > 0"></div>
          </div>
        </div>
      </div>
    </ng-container> -->

    <!-- Feature type nav item - display in external tab -->
    <a
      *ngIf="item.type === 'super_connector' && item.module_display === 'external_tab'"
      href="{{ item.url ? item.url : '#' }}"
      [ngStyle]="{ 'pointer-events': item.url ? 'unset' : 'none' }"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      class="nav-item"
      [ngClass]="{ 'nav-item-active': item.isActive || item.key == currentNavItemKey }"
    >
      <div class="nav-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <i class="show-label-icon label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
        <span class="label-title" *ngIf="item.name && !isCheckPlaceholder(item.name)">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.name && isCheckPlaceholder(item.name)"
          >&nbsp;&nbsp;{{ item.name | translate }}</span
        >
      </div>
    </a>

    <!-- Feature type nav item - display in embed iframe -->
    <!-- [routerLink]="[item.link, item.url]" -->
    <a
      *ngIf="item.type === 'super_connector' && item.module_display === 'embed_iframe'"
      (click)="changeTeamflarePageRoute(item)"
      [matTooltip]="item.nameStatic ? item.nameStatic : (item.name | translate)"
      matTooltipPosition="right"
      matTooltipClass="side-nav-tooltip"
      class="nav-item"
      [ngClass]="{ 'nav-item-active': item.isActive || item.key == currentNavItemKey }"
    >
      <div class="nav-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <i class="show-label-icon label-icon fa fa-fw fa-{{ item.iconClass }}" *ngIf="!item.imgUrl"></i>
        <span class="label-title" *ngIf="item.name && !isCheckPlaceholder(item.name)">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.name && isCheckPlaceholder(item.name)"
          >&nbsp;&nbsp;{{ item.name | translate }}</span
        >
      </div>
    </a>

    <!-- Label type nav item -->
    <a *ngIf="item.type === 'label'" class="nav-label-item">
      <div class="nav-module-label">
        <img class="label-img" [src]="item.imgUrl" *ngIf="item.imgUrl" />
        <span class="label-title" *ngIf="item.name">{{ item.name | translate }}</span>
        <span class="label-title" *ngIf="item.nameStatic">{{ item.nameStatic }}</span>
      </div>
    </a>

    <!-- Separator -->
    <div *ngIf="item.type === 'separator'" class="nav-label nav-separator theme-color-primary"></div>
  </ng-container>
</div>
