export const ZhChsLoginStrings = {
  GET_STATE: {
    DOMAIN: {
      INPUT_TIP: "输入网域",
      DOMAIN: "网域"
    },
    MOBILE: {
      INPUT_TIP: "输入电话号码",
      PHONE_NUMBER: "电话号码"
    },
    USERNAME: {
      INPUT_TIP: "输入用户名称",
      USERNAME: "用户名称"
    },
    EMPTY_ERROR: "请输入登入资讯",
    FAIL_ERROR: "未注册的帐户",
    ACCOUNT_LOCKED: "你的帐户已被锁定",
    SUBMIT: "提交",
    QR_CODE: {
      BUTTON: "以二维条码登入",
      TIP: `<p>
      在您的电脑上使用TeamNote Web Client:
      </p>
      <ol>
        <li>在您的手机开启TeamNote</li>
        <li>(Android) 点撃更多... → 登入网页客户端<br>
          (iOS) 点撃更多... → 关于 → 登入网页客户端</li>
        <li>以您的手机读取萤幕上的二维条码</li>
      </ol>`
    },
    INVALID_DOMAIN: "网域不正确"
  },
  OTP: {
    INPUT_TIP: "输入一次性密码 (OTP)",
    OTP: "OTP",
    FAIL_ERROR: "OTP不正确"
  },
  SECOND_FACTOR: {
    OTP_EMAIL: "一次性密码 (OTP) 将经由电邮送出",
    OTP_SMS: "一次性密码 (OTP) 将经由短讯送出",
    EMAIL: {
      TITLE: "电邮",
      INPUT_TIP: "输入电邮"
    },
    MOBILE: {
      TITLE: "电话号码",
      INPUT_TIP: "输入电话号码"
    },
    HKID: {
      TITLE: "香港身份证号码",
      INPUT_TIP: "输入香港身份证号码"
    },
    FULLNAME: {
      TITLE: "全名",
      INPUT_TIP: "输入你的全名"
    },
    LDAP_PW: {
      TITLE: "密码",
      INPUT_TIP: "输入密码"
    },
    STAFFID: {
      TITLE: "员工编号",
      INPUT_TIP: "输入员工编号"
    },
    STAFF_ID: {
      TITLE: "员工编号",
      INPUT_TIP: "输入员工编号"
    },
    FAIL_ERROR: "输入的资料不正确"
  },
  PASSWORD: {
    INPUT_TIP: "输入密码",
    NEW_PASSWORD_INPUT_TIP: "设定新密码",
    CONFIRM: "确认新密码",
    NEW_PASSWORD: "新密码",
    PASSWORD: "密码",
    SIGN_IN: "登入",
    FORGET_PASSWORD: "忘記密碼",
    SUBMIT: "提交",
    LOGIN_FAIL: "登入失败，请留意是否更改过密码及使用最新的正确密码登入",
    LOGIN_FAIL_AUTH: "登入失败",
    EMPTY_ERROR: "请输入密码",
    NOT_MATCH: "密码不匹配",
    NOT_MATCH_POLICY: "密码长度最少为{{NUM_CHAR}}个字，并且包括大小写英文字母",
    DEVICE_TOKEN_WIPE: "登入失败，设备令牌已被重置，请重试",
    MAX_REG_DEVICE: "你已经达到最大允许注册装置数目",
    EXPIRY_REMINDER: "你的密码将于{{NUM_DAY}}日后到期，请更改密码",
    PASSWORD_REUSED: "此密码曾使用过，请输入新的密码",
    ACCOUNT_SUSPENDED: "帐号已被停权",
  },
  EXERCISE: {
    LOGIN_EXERCISE: "登入演习"
  }
};
