import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { ModuleKeyDefinition } from '../../constants/module.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { RouteParamService } from '../route-param/route-param.service';
import { BaseRoutingService } from '../../routing/base-routing.service';
import { SideNavItem, SideNavService } from './side-nav.service';
import { TranslateManagerService } from '../../utilities/translate/translate-manager.service';
import { PageUrlConstant } from '../../constants/page-url.constant';
import { Router } from '@angular/router';
import { MultiChatRoomService } from '../../webclient/chat/multi-chat-room.service';
import { MultiChatRoomsPanel } from '../../webclient/chat/multi-chat-room.service';
import { Subscription } from 'rxjs';
import { ChatService } from '../../webclient/services/data/chat/chat.service';
import { DataManagerService } from '../../webclient/services/data/data-manager.service';
import { ModuleManagerService } from '../../webclient/services/module/module-manager.service';
import { AccountManagerService } from '../../webclient/services/account/account-manager.service';
import { ScheduledMessageService } from '../../webclient/schedule-message/schedule-message.service';

@Component({
  selector: 'tn-side-nav',
  templateUrl: './tn-side-nav.component.html',
  styleUrls: ['./tn-side-nav.component.scss']
})

export class TnSideNavComponent implements OnInit {
  sideNavItems: SideNavItem[];

  MODULE_KEYS = ModuleKeyDefinition;

  currentNavItemKey: string = null;

  IS_ENABLE_MULTI_CHATROOM_MODE: boolean = false;
  isEnableWebCommanderView: boolean = false;
  isShowMultiChatroomList: boolean = false;
  activeMultiChatroomPanel: any = null;
  multiChatRoomsPanels: any = [];
  private isChatLoadedSub: Subscription
  isChatLoaded: boolean = false

  private activeMultiChatRoomsPanelSub: Subscription;
  private multiChatRoomsPanelsSub: Subscription;


  private unapprovedScheduleMessagesSub: Subscription;
  unapprovedScheduleMessages: any = [];

  constructor(
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _routeParamService: RouteParamService,
    private _baseRoutingService: BaseRoutingService,
    private _sideNavService: SideNavService,
    private _translateManagerService: TranslateManagerService,
    private _router: Router,
    private _multiChatRoomService: MultiChatRoomService,
    private _chatService: ChatService,
    private _dataManagerService: DataManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _accountManagerService: AccountManagerService,
    private _scheduledMessageService: ScheduledMessageService,
  ) { 
    this.sideNavItems = [];

    this._sideNavService.sideNavItems$.subscribe(sideNavs => {
      this.sideNavItems = sideNavs;
      this.currentNavItemKey = this._sideNavService.currentActiveItemKey;

      this.updateSideNavModuleName();
    });

    // translate the side nav module name after switching language
    this._translateManagerService.currentTranslation$.subscribe(lang => {
      this.updateSideNavModuleName();
    });

 
    this.IS_ENABLE_MULTI_CHATROOM_MODE = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ENABLE_MULTI_CHATROOM_MODE;
    
    this.activeMultiChatRoomsPanelSub = this._multiChatRoomService.activeMultiChatRoomsPanel$.subscribe(active => {
      this.activeMultiChatroomPanel = active;
    });

    this.multiChatRoomsPanelsSub = this._multiChatRoomService.multiChatRoomsPanels$.subscribe(panels => {
      console.log('new panels', panels);
      this.multiChatRoomsPanels = panels;

      if (this.multiChatRoomsPanels && _.isArray(this.multiChatRoomsPanels)) {
        if (this.multiChatRoomsPanels.length) {
          this.isShowMultiChatroomList = true;
        }
      }
    });

    this.unapprovedScheduleMessagesSub = this._scheduledMessageService.unapprovedScheduleMessages$.subscribe(messages => {
      // this.unapprovedScheduleMessages = messages;
      this.unapprovedScheduleMessages = _.cloneDeep(this._scheduledMessageService.unapprovedScheduleMessages);
    });
  }

  ngOnInit() {
    this.restorePrevSideNav();

    this.isChatLoadedSub = this._dataManagerService.isChatLoaded$.subscribe(isChatLoaded => {
      // console.log('Chat just Loaded')
      if (isChatLoaded) {
        // this.isEnableWebCommanderView = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.WEB_COMMANDER_VIEW) && this._accountManagerService.isEnableMultiAccountLogin()
        if (this._accountManagerService.isEnableMultiAccountLogin()) {
          this.isEnableWebCommanderView = this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.WEB_COMMANDER_VIEW) && this._accountManagerService.isEnableMultiAccountLogin()
        }

        console.log('this.isEnableWebCommanderView', this.isEnableWebCommanderView);
        this.isChatLoaded = true;
      }
    })
  }

  ngOnDestroy() {
    this.activeMultiChatRoomsPanelSub.unsubscribe();
    this.multiChatRoomsPanelsSub.unsubscribe();
    this.unapprovedScheduleMessagesSub.unsubscribe();
    this.isChatLoadedSub.unsubscribe()
  }

  isCheckPlaceholder(module_name: string) {
    return module_name.charAt(0) === ' '
  }

  updateSideNavModuleName(): void {
    _.forEach(this.sideNavItems, (module) => {
      if (module._i18n) {
        let i18n = module._i18n[this._translateManagerService.getCurrentLangI18nKey()];
        
        if (i18n && i18n.side_menu_name !== '') {
          module.name = i18n.side_menu_name;
        }
      }
    })
  }

  restorePrevSideNav(): void {
    let prev = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    this.currentNavItemKey = prev ? prev : this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE;

    this._sideNavService.updateActiveSideNav(this.currentNavItemKey);
  }

  changeRoute(navItem: SideNavItem, prevAction?: string): void {
    this.currentNavItemKey = navItem.key;

    if (!prevAction) {
      // if (this.currentNavItemKey === ModuleKeyDefinition.CHAT) {
        if (this.activeMultiChatroomPanel) {
          this._multiChatRoomService.updateMultiChatRoomsPanelsSubject();
          this._multiChatRoomService.updateActiveMultiChatRoomsPanelSubject(null);
        }
      // }
    } 

    this._sideNavService.updateActiveSideNav(navItem.key);
    
    this._routeParamService.setRouteParam(navItem.params);
  }

  changeTeamflarePageRoute(navItem: SideNavItem): void {
    this.currentNavItemKey = navItem.key;

    this._sideNavService.updateActiveSideNav(navItem.key);
    
    this._routeParamService.setRouteParam(navItem.params);

    if (!navItem.url) {
      alert('TeamFlare URL is invalid')
      return
    }

    // route to path [API_HOST]/#/webclient/teamflare_app/https://xxx.com
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + navItem.link.slice(1), navItem.url]);
  }

  onOpenMultiChatroomList(event: MouseEvent): void {
    if (!this.IS_ENABLE_MULTI_CHATROOM_MODE) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.isShowMultiChatroomList = !this.isShowMultiChatroomList
  }

  onNewMultiChatroomPanel(event: MouseEvent, navItem: any): void {
    this._multiChatRoomService.appendMultiChatroomPanel()

    if (this.currentNavItemKey !== 'chat') {
      this.changeRoute(navItem);
      this._chatService.goToChatPage()
    }
  }

  onEnterTargetMultiChatroomPanel(event: MouseEvent, panel: MultiChatRoomsPanel, navItem: any): void {
    if (this.activeMultiChatroomPanel) {
      if (this.activeMultiChatroomPanel.panelId === panel.panelId) {
        return;
      }
    }
    
    this._multiChatRoomService.updateActiveMultiChatRoomsPanelSubject(panel.panelId);

    if (this.currentNavItemKey !== 'chat') {
      this.changeRoute(navItem, 'onEnterMultiChatroom');
      this._chatService.goToChatPage()
    }
  }
}
