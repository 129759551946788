export const ZhChtChatStrings = {
  HEADER_ANNOUNCEMENT: "公告",
  HEADER_GROUP: "群組",
  HEADER_INDIVIDUAL: "聯絡人",
  HEADER_FAVORITE: "最愛",
  HEADER_MESSAGE: "訊息",

  ADD_FAVORITE: "加到最愛",
  REMOTE_FAVORITE: "從最愛中移除",
  SEE_MORE: "查看更多",

  CONFIRM_FORWARD: "確定轉發訊息到 {{USER_NAME}}?",

  RECEIVED_FILE: "收到檔案",
  SENT_FILE: "發送了檔案",
  RECEIVED_STICKER: "{{SENDER}} 傳送了貼圖",
  SENT_STICKER: "貼圖已傳送",
  REMOVE: "刪除",

  NEW_GROUP: "建立群組",
  BROADCAST: "廣播",
  SEARCH_MESSAGE: "搜尋訊息",
  SORT_TIME: "依時間排序",
  SORT_GROUP: "依群組排序",
  SEARCH_UNFILTERED: "篩選",
  SEARCH_FILTERED_ONE: "已篩選1名用戶",
  SEARCH_FILTERED_MANY: "已篩選{{COUNT}}名用戶",

  RESTRICTED_CHAT_OPEN_IN_APP_MSG: "基於安全原因，此聊天室只能在App中打開。",
  CONFIDENTIAL_MSG_PREVIEW: "**機密訊息**",

  ENCRYPTED_MSG_PREVIEW: "**安全訊息**",

  CHAT_GROUP: {
    GROUP_NAME: "群組名稱",
    ADD_MEMBER: "新增群組成員",
    ADD_MEMBER_FROM_CHAT_GROUP: "從其它群組新增成員",
    ADD_MEMBER_FROM_BROADCAST: "從廣播列表新增成員",
    NO_MEMBER: "沒有群組成員",
    MEMBER_COUNT: {
      ONE: "{{NUM}}位群組成員",
      MULTIPLE: "{{NUM}}位群組成員"
    },
    NORMAL_GROUP: "一般群組",
    ANNOUNCEMENT_GROUP: "公告群組",
    GROUP_TYPE: "群組類型",
    GORUP_LEVEL: "訊息分級",

    ERROR_MSG: {
      NO_GROUP_NAME: "Please input group name",
      NO_MEMBER: "Please select group member",
      UPDATE_COMMANDER_VIEW_FAIL: "Commander view update failed. Please try again",
      UPDATE_COMMANDER_VIEW_SUCCESS: "Commander view updated successfully",
      INCORRECT_TIME_RANGE: "Please check if the time range is correct",
      INCORRECT_TIME_RANGE_ORDER: "Please enter the time range in the correct order"
    },

    ASSIGN_ADMIN: "指派群組管理員",
    ADD_ADMIN: "新增群組管理員",
    REMOVE_MEMBER: "移除群組成員",
    REMOVE_MEMBER_CONFIRM: "確認要將 {{USER}} 從群組中移除?",
    NOTIFICATION: {
      TITLE: "靜音通知",
      ONE_HOUR: "1 小時",
      TWO_HOURS: "2 小時",
      EIGHT_HOURS: "8 小時",
      ONE_WEEK: "1 星期",
      ONE_YEAR: "1 年",
      FOREVER: "永久",
      UNTIL: "直到 "
    },
    LEAVE_GROUP: "離開群組",
    LEAVE_GROUP_CONFIRM: "確定要離開此群組?",
    DELETE_GROUP: "刪除群組",
    DELETE_GROUP_CONFIRM: "確定要刪除群組?",
    REMOVE_ALL: "刪除所有已選擇的成員",
    CHAT_REMOVED_TIPS: "聊天室已刪除，或您已被聊天群組管理員移除。面板已相應更新",
    MULTICHAT_GROUPS_COUNT: "群組",
    DATE_TIME_RANGE: "日期和時間範圍"
  },
  
  CONTACT_CARD: {
    WHISPER: "私訊",
    CHAT: "聊天",
    IMPORTANT_USER: {
      SET: "重要用戶",
      UNSET: "非重要用戶"
    }
  },
  DATE_FILTER: '日期',
  DELETE_PANEL: '刪除面板',
  APPEND_CHATROOM: '添加聊天室',
  ADD_PANEL: '添加聊天室面板'

}
