import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog'
import { FormGroup, FormControl } from '@angular/forms'
import { TimestampService } from '../timestamp/timestamp.service'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { DatePipe } from '@angular/common'
import _ from 'lodash'

import setHours from 'date-fns/set_hours'
import setMinutes from 'date-fns/set_minutes'
import getHours from 'date-fns/get_hours'

import { TnNotificationService } from '../tn-notification/tn-notification.service'
// import { DATE_FORMATS } from './date-time-selector.service'

const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@Component({
  selector: 'tn-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    // DatePipe
  ]
})
export class DateTimeSelectorComponent {
  header: string = ''
  callback: Function | null = null

  // dateRange = new FormGroup({
  //   start: new FormControl<Date | null>(null),
  //   end: new FormControl<Date | null>(null)
  // })

  dateRange = {
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null)
  }

  timeRange: any = {
    start: null,
    end: null
  }

  submitDateTimeRange: any = {
    from: null,
    to: null
  }

  constructor(
    public dialogRef: MatDialogRef<DateTimeSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _timestampService: TimestampService,
    private _tnNotificationService: TnNotificationService,
  ) {}

  ngOnInit() {
    // this.dialogRef.afterClosed().subscribe(() => {
    //   this.dialogRef.close();
    // });
    this.header = this.data.header
    
    if (this.data.selectedDateTimeRange) {
      this.initSelectedDateTime();
    }

    this.callback = this.data.callback
  }

  confirm(): void {
    if (!this.checkDateTimeInput()) {
      return;
    }

    // console.log('this.dateRange', this.dateRange)
    // console.log('timeRange', this.timeRange)
    // console.log('this.submitDateTimeRange', this.submitDateTimeRange);
 
    if (this.callback) {
      this.callback(this.submitDateTimeRange);
      this.cancel();
    }
  }

  clear(): void {
    // this.dateRange = new FormGroup({
    //   start: new FormControl<Date | null>(null),
    //   end: new FormControl<Date | null>(null)
    // })

    // this.timeRange = {
    //   start: null,
    //   end: null
    // }

    if (this.callback) {
      this.callback(null);
      this.cancel();
    }
  }

  cancel(): void {
    this.dialogRef.close()
  }

  initSelectedDateTime(): void {
    // console.log('this.data.selectedDateTimeRange', this.data.selectedDateTimeRange);

    const { from, to } = this.data.selectedDateTimeRange;


    let start = new Date(from * 1000);
    let end = new Date(to * 1000);

    // console.log(start.setHours(0, 0, 0, 0));
    // console.log(end.setHours(0, 0, 0, 0));

    // this.dateRange = new FormGroup({
    //   start: new FormControl<Date | null>(new Date(start.setHours(0, 0, 0, 0))),
    //   end: new FormControl<Date | null>(new Date(end.setHours(0, 0, 0, 0)))
    // })

    this.dateRange = {
      start: new FormControl<Date | null>(new Date(start.setHours(0, 0, 0, 0))),
      end: new FormControl<Date | null>(new Date(end.setHours(0, 0, 0, 0)))
    }

    // console.log('this.dateRange', this.dateRange);


    let start_display = this._timestampService.getTimeDisplay(from);
    let end_display = this._timestampService.getTimeDisplay(to);
    // console.log('start_display', start_display);
    // console.log('end_display', end_display);



    this.timeRange = {
      start: start_display,
      end: end_display
    }

    // console.log('this.timeRange', this.timeRange);
  }

  checkDateTimeInput(): boolean {
    // const hasValue = this.dateRange.value.start && this.dateRange.value.end

    // if (this.dateRange?.invalid || !hasValue) {
    //   this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE');
    //   return false
    // }
    if (!this.dateRange.start.value || !this.dateRange.end.value) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE');
      return false
    }

    // let startDateTimestamp = this._timestampService.dateObjToSecondString(new Date(this.dateRange.start.value)) ;
    // let endDateTimestamp = this._timestampService.dateObjToSecondString(new Date(this.dateRange.end.value));
    // console.log(this._timestampService.dateObjToSecondString(this.dateRange.start.value));
    // this._timestampService.checkIfTimeCorrectOrder(lastAcceptTime, newUpdateTime);
    // if (!this._timestampService.checkIfTimeCorrectOrder(startDateTimestamp, endDateTimestamp)) {
      // this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE');
      // return false
    // }

    if (!this.timeRange.start || !this.timeRange.end) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE');
      return false
    }

    if (this.timeRange.start === 'Invalid DateTime' || this.timeRange.end === 'Invalid DateTime') {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE');
      return false
    }

    if (!this.checkDateIsInCorrectOrder()) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.INCORRECT_TIME_RANGE_ORDER');
      return false;
    }

    return true
  }

  checkDateIsInCorrectOrder(): boolean {
    // start datetime
    let [ s_h, s_m ] = this.timeRange.start.split(':');
    let from = _.cloneDeep(new Date(this.dateRange.start.value));

    from = setHours(from, Number(s_h))
    from = setMinutes(from, Number(s_m))
    // console.log(from);
    // console.log(from.getTime() / 1000);
    
    // end datetime
    let [ e_h, e_m ] = this.timeRange.end.split(':');
    let to = _.cloneDeep(new Date(this.dateRange.end.value));

    to = setHours(to, Number(e_h))
    to = setMinutes(to, Number(e_m))


    // console.log(to);
    // console.log(to.getTime() / 1000);
    if (this._timestampService.checkIfTimeCorrectOrder(from.getTime() / 1000, to.getTime() / 1000)) {
      this.submitDateTimeRange = {
        from: (from.getTime() / 1000).toString(),
        to: (to.getTime() / 1000).toString(),
      }

      return true
    } 

    return false
  }

  // checkDateIsInCorrectOrder_v1(): boolean {
  //   // start datetime
  //   let [ s_h, s_m ] = this.timeRange.start.split(':');
  //   let from = _.cloneDeep(new Date(this.dateRange.value.start));

  //   from = setHours(from, Number(s_h))
  //   from = setMinutes(from, Number(s_m))
  //   // console.log(from);
  //   // console.log(from.getTime() / 1000);
    
  //   // end datetime
  //   let [ e_h, e_m ] = this.timeRange.end.split(':');
  //   let to = _.cloneDeep(new Date(this.dateRange.value.end));

  //   to = setHours(to, Number(e_h))
  //   to = setMinutes(to, Number(e_m))


  //   // console.log(to);
  //   // console.log(to.getTime() / 1000);
  //   if (this._timestampService.checkIfTimeCorrectOrder(from.getTime() / 1000, to.getTime() / 1000)) {
  //     this.submitDateTimeRange = {
  //       from: (from.getTime() / 1000).toString(),
  //       to: (to.getTime() / 1000).toString(),
  //     }

  //     return true
  //   } 

  //   return false
  // }

  // get isDateTimeValid() {
  //   const hasValue = this.dateRange.value.start && this.dateRange.value.end;

  //   if (!this.dateRange?.invalid && hasValue) {
  //     return false;
  //   }

  //   if (!this.timeRange.start || !this.timeRange.end) {
  //     return false
  //   }

  //   if (this.timeRange.start === 'Invalid DateTime' || this.timeRange.end === 'Invalid DateTime') {
  //     return false;
  //   }

  //   return true;
  // }
}
